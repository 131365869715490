/* COLORS */
/* BRAND COLORS */
/* GLOBAL */
/* SOCIAL MEDIA */
/* BLOCKS POSITIONS - HOME (only for homepage, include partial below) */
/* BUTTONS */
/* HEADER */
/* HEADER - PHONENUMER */
/* HEADER - ACCOUNT */
/* HEADER - LANGPICKER */
/* FOOTER - DARK */
/* SLIDER */
/* CONTACTBLOCKS */
/* GRIDCARDS */
/* API */
/* BREADCRUMB */
/* CONTACT */
/* EMPLOYEES*/
/* TESTIMONIALS */
/* NEWS */
/* PROMO */
/* QUICKSEARCH */
/* SPECIALS */
/* RECENTLY VIEWED */
/* TESTIMONIALS */
/* BRANCHELOGOS */
/* GLOBAL*/
/* https://css-tricks.com/snippets/sass/centering-mixin/*/
/* https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/*/
/* Button variants*/
/* -------------------------*/
/* Easily pump out default styles, as well as :hover, :focus, :active,*/
/* and disabled options for all buttons*/
/* Button sizes*/
/* -------------------------*/
.btn {
  display: inline-block;
  margin-bottom: 0;
  /* For input.btn*/
  font-weight: 300;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214*/
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 0;
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  /* Future-proof disabling of clicks*/
}

/* Alternate buttons*/
/* --------------------------------------------------*/
.btn-default {
  color: #444;
  background-color: #d6d6d6;
  border-color: #d6d6d6;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active {
  color: #444;
  background-color: rgb(193.6, 193.6, 193.6) !important;
  border-color: rgb(193.6, 193.6, 193.6) !important;
}
.open .btn-default.dropdown-toggle {
  color: #444;
  background-color: rgb(193.6, 193.6, 193.6);
  border-color: rgb(193.6, 193.6, 193.6);
}
.btn-default:active, .btn-default.active {
  background-image: none;
}
.open .btn-default.dropdown-toggle {
  background-image: none;
}
.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default[disabled].active, fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
  background-color: #d6d6d6;
  border-color: #d6d6d6;
}
.btn-default .badge {
  color: #d6d6d6;
  background-color: #444;
}

.btn-primary {
  color: #fff;
  background-color: #ed6b06;
  border-color: #ed6b06;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
  color: #fff;
  background-color: rgb(197.2074074074, 89.0345679012, 4.9925925926) !important;
  border-color: rgb(197.2074074074, 89.0345679012, 4.9925925926) !important;
}
.open .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(197.2074074074, 89.0345679012, 4.9925925926);
  border-color: rgb(197.2074074074, 89.0345679012, 4.9925925926);
}
.btn-primary:active, .btn-primary.active {
  background-image: none;
}
.open .btn-primary.dropdown-toggle {
  background-image: none;
}
.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: #ed6b06;
  border-color: #ed6b06;
}
.btn-primary .badge {
  color: #ed6b06;
  background-color: #fff;
}

/* Success appears as green*/
.btn-success {
  color: #fff;
  background-color: #ed6b06;
  border-color: #ed6b06;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active {
  color: #fff;
  background-color: rgb(197.2074074074, 89.0345679012, 4.9925925926) !important;
  border-color: rgb(197.2074074074, 89.0345679012, 4.9925925926) !important;
}
.open .btn-success.dropdown-toggle {
  color: #fff;
  background-color: rgb(197.2074074074, 89.0345679012, 4.9925925926);
  border-color: rgb(197.2074074074, 89.0345679012, 4.9925925926);
}
.btn-success:active, .btn-success.active {
  background-image: none;
}
.open .btn-success.dropdown-toggle {
  background-image: none;
}
.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success[disabled].active, fieldset[disabled] .btn-success, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success.active {
  background-color: #ed6b06;
  border-color: #ed6b06;
}
.btn-success .badge {
  color: #ed6b06;
  background-color: #fff;
}

/* Info appears as blue-green*/
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
  color: #fff;
  background-color: rgb(18.4666666667, 130.0695652174, 147.7333333333) !important;
  border-color: rgb(18.4666666667, 130.0695652174, 147.7333333333) !important;
}
.open .btn-info.dropdown-toggle {
  color: #fff;
  background-color: rgb(18.4666666667, 130.0695652174, 147.7333333333);
  border-color: rgb(18.4666666667, 130.0695652174, 147.7333333333);
}
.btn-info:active, .btn-info.active {
  background-image: none;
}
.open .btn-info.dropdown-toggle {
  background-image: none;
}
.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info[disabled].active, fieldset[disabled] .btn-info, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info.active {
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info .badge {
  color: #17a2b8;
  background-color: #fff;
}

/* Warning appears as orange*/
.btn-warning {
  color: #fff;
  background-color: #e9911d;
  border-color: #e9911d;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active {
  color: #fff;
  background-color: rgb(201.5774193548, 123.0870967742, 19.6225806452) !important;
  border-color: rgb(201.5774193548, 123.0870967742, 19.6225806452) !important;
}
.open .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: rgb(201.5774193548, 123.0870967742, 19.6225806452);
  border-color: rgb(201.5774193548, 123.0870967742, 19.6225806452);
}
.btn-warning:active, .btn-warning.active {
  background-image: none;
}
.open .btn-warning.dropdown-toggle {
  background-image: none;
}
.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning[disabled].active, fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning.active {
  background-color: #e9911d;
  border-color: #e9911d;
}
.btn-warning .badge {
  color: #e9911d;
  background-color: #fff;
}

/* Danger and error appear as red*/
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active {
  color: #fff;
  background-color: rgb(197.9088607595, 34.2911392405, 49.9670886076) !important;
  border-color: rgb(197.9088607595, 34.2911392405, 49.9670886076) !important;
}
.open .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: rgb(197.9088607595, 34.2911392405, 49.9670886076);
  border-color: rgb(197.9088607595, 34.2911392405, 49.9670886076);
}
.btn-danger:active, .btn-danger.active {
  background-image: none;
}
.open .btn-danger.dropdown-toggle {
  background-image: none;
}
.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger[disabled].active, fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger .badge {
  color: #dc3545;
  background-color: #fff;
}

/* Link buttons*/
/* -------------------------*/
/* Make a button look and behave like a link*/
.btn-link {
  color: #352874;
  font-weight: normal;
  cursor: pointer;
  border-radius: 0;
}
.btn-link, .btn-link:active, .btn-link[disabled], fieldset[disabled] .btn-link {
  background-color: transparent;
}
.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: transparent;
}
.btn-link:hover, .btn-link:focus {
  color: rgb(35.6730769231, 26.9230769231, 78.0769230769);
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus {
  color: #999999;
  text-decoration: none;
}

/* Button Sizes*/
/* --------------------------------------------------*/
.btn-lg {
  /* line-height: ensure even-numbered height of button next to large input*/
  padding: 0.6rem 1.2rem;
  border-radius: 0;
}

.btn-sm {
  /* line-height: ensure proper height of button next to small input*/
  padding: 0.4rem 1rem;
  border-radius: 0;
}

.btn-xs {
  padding: 0.2rem 0.5rem;
  border-radius: 0;
}

/* Block button*/
/* --------------------------------------------------*/
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

/* Vertically space out multiple block buttons*/
.btn-block + .btn-block {
  margin-top: 5px;
}

/* Specificity overrides*/
input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

/* custom buttons */
.btn-icon {
  padding-left: 32px;
  position: relative;
}
.btn-icon i {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 25px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px 0 0 4px;
}
.btn-icon.btn-icon-right {
  padding-left: 12px;
  padding-right: 32px;
}
.btn-icon.btn-icon-right i {
  right: 0;
  left: auto;
  border-radius: 0 4px 4px 0;
}
.btn-icon.btn-xs {
  padding-left: 25px;
}
.btn-icon.btn-xs i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  border-radius: 3px 0 0 3px;
}
.btn-icon.btn-xs.btn-icon-right {
  padding-left: 5px;
  padding-right: 25px;
}
.btn-icon.btn-xs.btn-icon-right i {
  border-radius: 0 3px 3px 0;
}
.btn-icon.btn-sm {
  padding-left: 32px;
}
.btn-icon.btn-sm i {
  width: 25px;
  height: 28px;
  line-height: 28px;
  font-size: 11px;
  border-radius: 3px 0 0 3px;
}
.btn-icon.btn-sm.btn-icon-right {
  padding-left: 10px;
  padding-right: 35px;
}
.btn-icon.btn-sm.btn-icon-right i {
  border-radius: 0 3px 3px 0;
}
.btn-icon.btn-lg {
  padding-left: 50px;
}
.btn-icon.btn-lg i {
  width: 40px;
  height: 44px;
  line-height: 44px;
  font-size: 22px;
  border-radius: 6px 0 0 6px;
}
.btn-icon.btn-lg.btn-icon-right {
  padding-left: 16px;
  padding-right: 55px;
}
.btn-icon.btn-lg.btn-icon-right i {
  border-radius: 0 6px 6px 0;
}
.btn-icon.btn-default i {
  background: rgb(185.95, 185.95, 185.95);
}
.btn-icon.btn-primary i {
  background: rgb(182.2851851852, 82.2975308642, 4.6148148148);
}
.btn-icon.btn-success i {
  background: rgb(182.2851851852, 82.2975308642, 4.6148148148);
}
.btn-icon.btn-info i {
  background: rgb(16.7666666667, 118.0956521739, 134.1333333333);
}
.btn-icon.btn-warning i {
  background: rgb(187.6346774194, 114.5733870968, 18.2653225806);
}
.btn-icon.btn-danger i {
  background: rgb(184.8683544304, 32.0316455696, 46.6746835443);
}

/* Icon container */
.icon-container {
  position: relative;
  display: inline-block;
}
.icon-container i {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 25px;
  height: 31px;
  line-height: 31px;
  background: rgb(183.4, 183.4, 183.4);
  border-radius: 4px 0 0 4px;
}
.icon-container .btn {
  padding-left: 32px;
}
.icon-container.icon-container-xs i {
  width: 20px;
  height: 19px;
  line-height: 19px;
  font-size: 11px;
  border-radius: 3px 0 0 3px;
  top: 2px;
}
.icon-container.icon-container-xs .btn-xs {
  padding-left: 25px;
}
.icon-container.icon-container-sm i {
  width: 25px;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  border-radius: 3px 0 0 3px;
}
.icon-container.icon-container-sm .btn-sm {
  padding-left: 30px;
}
.icon-container.icon-container-lg i {
  width: 40px;
  height: 44px;
  line-height: 44px;
  font-size: 22px;
  border-radius: 6px 0 0 6px;
}
.icon-container.icon-container-lg .btn-lg {
  padding-left: 50px;
}
.icon-container.icon-container-default i {
  background: rgb(185.95, 185.95, 185.95);
}
.icon-container.icon-container-primary i {
  background: rgb(182.2851851852, 82.2975308642, 4.6148148148);
}
.icon-container.icon-container-success i {
  background: rgb(182.2851851852, 82.2975308642, 4.6148148148);
}
.icon-container.icon-container-info i {
  background: rgb(16.7666666667, 118.0956521739, 134.1333333333);
}
.icon-container.icon-container-warning i {
  background: rgb(187.6346774194, 114.5733870968, 18.2653225806);
}
.icon-container.icon-container-danger i {
  background: rgb(184.8683544304, 32.0316455696, 46.6746835443);
}

.form-control {
  border-radius: 0;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #444444;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

a {
  color: #352874;
}
a:hover {
  color: rgb(35.6730769231, 26.9230769231, 78.0769230769);
}

.wrapper {
  padding-top: calc(3rem + 2rem);
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 992px) {
  .wrapper {
    padding-top: calc(3.625rem + 2rem);
  }
}
.wrapper .main {
  padding-bottom: 1rem;
}

.check-list li:before {
  color: #ed6b06;
}

.fullwidth {
  background-color: #f9f7f5;
}
.fullwidth.search #object-search .tab-content .tab-pane {
  background-color: #f9f7f5;
}

.cc-link {
  color: #352874 !important;
}

.article-title,
h3 {
  color: #352874;
  letter-spacing: 0.05rem;
  word-break: break-word;
}

.lead {
  font-style: italic;
  color: #ed6b06;
}

.home h2 {
  letter-spacing: 0.15rem;
  color: #352874;
}
.home .textblock7 .lead,
.home .textblock7 .article-title {
  color: #fff;
  font-style: normal;
}

/* max 576px */
/* max 576px */
/* max 576px */
/* 576px */
@media (min-width: 1200px) {
  .page35 .sidebar {
    padding: 2rem 15px 2rem 50px;
  }
}
/* LAYOUT*/
.home .wrapper .mainnavcontainer {
  order: 0;
}
.home .wrapper .slider-container {
  order: 1;
}
.home .wrapper .ctaBlocks {
  order: 2;
}
.home .wrapper .quicksearch {
  order: 3;
}
.home .wrapper .main-content {
  order: 5;
}
.home .wrapper .specials {
  order: 6;
}
.home .wrapper .contentBlocks {
  order: 7;
}
.home .wrapper .textblock7 {
  order: 8;
}
.home .wrapper .textblock8 {
  order: 9;
}
.home .wrapper .employees {
  order: 10;
}
.home .wrapper .news {
  order: 11;
}
.home .wrapper .recentlyviewed {
  order: 12;
}
.home .wrapper .branchelogos {
  order: 13;
}
.home .wrapper .websiteFooter {
  order: 99;
}

/* uncomment to define order of home page */
.ctaBlocks {
  background-color: #f2f2f2;
}
.ctaBlocks .ctaBlock {
  background-color: #fff;
  box-shadow: 0 2px 0 0 #edeae8;
}
.ctaBlocks .ctaBlock .text {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ctaBlocks .ctaBlock .text p:first-of-type {
  flex-grow: 1;
}

.contentBlocks {
  background-color: #fff;
}

.home .main-content {
  background-color: #fff;
  padding: 60px 0;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 1200px) {
  .home .main-content {
    padding: 100px 0;
  }
}
.home .main-content > .container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 768px) {
  .home .main-content > .container {
    flex-flow: row nowrap;
  }
}
.home .main-content > .container .text {
  color: #444444;
}
.home .main-content > .container > div {
  flex: 100% 0 1;
  padding: 0 15px;
}
.home .contentBlocks {
  background-color: rgb(59.25, 26.75, 1.5);
  color: #fff;
  /*
  .textblock4, .textblock5, .textblock6 {
      text-align: center;
  } 
  */
}
.home .textblock7 {
  display: flex;
  position: relative;
  align-items: center;
  background-color: rgb(118.5, 53.5, 3);
  padding: 60px 0 calc(60px - 1rem) !important;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
  padding: 0;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 1200px) {
  .home .textblock7 {
    padding: 100px 0 calc(100px - 1rem) !important;
  }
}
.home .textblock7 .text {
  position: relative;
  z-index: 2;
  color: #444444;
}
.home .textblock7 .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.home .textblock7 .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 1200px) {
  .home .textblock7 {
    padding: 60px 0 calc(60px - 1rem);
  }
}
.home .textblock7 .text {
  text-align: center;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 15px;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 992px) {
  .home .textblock7 .text {
    margin-bottom: 25px;
  }
}
.home .textblock7 .text .nav-pills .nav-item .nav-link {
  padding: 1rem;
  background-color: #f9f7f5;
  border-top: 3px solid #f9f7f5;
  border-bottom: 3px solid #f9f7f5;
}
.home .textblock7 .text .nav-pills .nav-item .nav-link.active {
  background-color: #fff;
  color: #444444;
  border-top: 3px solid #ed6b06;
  border-bottom: 3px solid #fff;
}
.home .textblock7 .text .tab-content .tab-pane {
  padding: 2rem;
  min-height: 360px;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 768px) {
  .home .textblock7 .text .tab-content .tab-pane {
    padding: 2rem 3rem;
  }
}
.home .textblock7 .text .tab-content .tab-pane p:last-child {
  margin-bottom: 0;
}
.home .textblock8 {
  background-color: #f9f7f5;
  padding: 60px 0;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 1200px) {
  .home .textblock8 {
    padding: 100px 0;
  }
}
.home .textblock8 > .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
}
.home .textblock8 > .container > div {
  flex: 100% 0 1;
  padding: 0 15px;
}
.home .textblock8 > .container .text {
  order: 2;
  color: #444444;
}
.home .textblock8 > .container .image {
  order: 1;
  margin-bottom: 1em;
}

.sys-textblock .text .nav-pills .nav-item .nav-link {
  border-radius: 0;
}

.mainnav.navbar {
  opacity: 1;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
}
.mainnav.navbar .navbar-brand {
  height: calc(3rem + 2rem);
  width: calc(8.375rem + 1rem);
  position: relative;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 992px) {
  .mainnav.navbar .navbar-brand {
    border-right: transparent;
    height: calc(3.625rem + 2rem);
    width: calc(10rem + 2rem);
    z-index: 998;
  }
}
.mainnav.navbar .navbar-brand img {
  opacity: 1;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid transparent;
  background: #fff;
  left: 0;
  top: 0;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 576px) {
  .mainnav.navbar .navbar-brand img {
    border-right: 1px solid #edeae8;
  }
}
.mainnav.navbar .navbar-brand.affix {
  padding: 10px;
}
.mainnav.navbar .navbar-brand.affix img {
  padding: 10px;
  height: calc(3rem + 1rem);
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 992px) {
  .mainnav.navbar .navbar-brand.affix img {
    height: calc(3.625rem + 1rem);
  }
}
.mainnav.navbar .navbar-brand.affix:after {
  height: calc(3rem + 1rem);
  opacity: 0;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 992px) {
  .mainnav.navbar .navbar-brand.affix:after {
    height: calc(3.625rem + 1rem);
  }
}
.mainnav.navbar .navbar-toggler {
  order: 99;
  background-color: #ed6b06;
  border-color: #ed6b06;
}
.mainnav.navbar .navbar-toggler .icon {
  color: #fff;
}
.mainnav.navbar .navbar-toggler .icon span {
  background: #fff;
}
.mainnav.navbar .mainnavbar {
  order: 2;
  letter-spacing: 0.05rem;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (max-width: 992px) {
  .mainnav.navbar .mainnavbar.navbar-collapse {
    top: calc(3rem + 2rem);
    border-top: 1px solid #edeae8;
    background-color: #fff;
    height: calc(100vh - 58px) !important;
  }
}
.mainnav.navbar .mainnavbar .navbar-nav,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main {
  width: 100%;
  height: auto;
  font-size: 14px;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item {
  padding: 0 10px;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item:nth-last-of-type(2),
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item:nth-last-of-type(2) {
  margin-left: auto;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item:nth-last-of-type(2) > a,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item:nth-last-of-type(2) > a {
  background-image: url("//hayweb.blob.core.windows.net/public/00_branchelogos/eerlijkbieden/eerlijkbieden.svg");
  color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 10rem;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item:nth-last-of-type(2) > a:hover,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item:nth-last-of-type(2) > a:hover {
  color: transparent;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item:last-of-type > a,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item:last-of-type > a {
  background-image: url("//hayweb.blob.core.windows.net/public/00_branchelogos/VastgoedNed/vastgoedned.svg");
  color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 5rem;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item:last-of-type > a:hover,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item:last-of-type > a:hover {
  color: transparent;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .nav-link,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .nav-link {
  border-bottom: 1px solid #edeae8;
  color: #352874;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 992px) {
  .mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .nav-link,
  .mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .nav-link {
    border-bottom: 0;
  }
}
@media (max-width: 992px) {
  .mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .nav-link,
  .mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .nav-link {
    border-bottom: 1px solid #edeae8;
    color: #444444;
  }
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .dropdown-menu,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .dropdown-menu {
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 992px) {
  .mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .dropdown-menu,
  .mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .dropdown-menu {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
    top: calc(3.625rem + 2rem);
    background-color: #f2f2f2;
    justify-content: center;
  }
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .dropdown-menu > .dropdown-item > .nav-link:hover,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .dropdown-menu > .dropdown-item > .nav-link:hover {
  color: rgb(212.1296296296, 95.7716049383, 5.3703703704);
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .dropdown-menu > .dropdown-item:last-child > .nav-link,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .dropdown-menu > .dropdown-item:last-child > .nav-link {
  border-bottom: 0;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .dropdown-menu > .dropdown-item.active,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .dropdown-menu > .dropdown-item.active {
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 1200px) {
  .mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .dropdown-menu > .dropdown-item.active,
  .mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .dropdown-menu > .dropdown-item.active {
    background-color: #f2f2f2;
  }
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .dropdown-menu > .dropdown-item.active > .nav-link,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .dropdown-menu > .dropdown-item.active > .nav-link {
  color: #ed6b06;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 1200px) {
  .mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .dropdown-menu > .dropdown-item.active > .nav-link,
  .mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .dropdown-menu > .dropdown-item.active > .nav-link {
    background-color: #f2f2f2;
  }
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  background-color: #f2f2f2;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item:hover > .nav-link,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item:hover > .nav-link {
  color: #ed6b06;
}
.mainnav.navbar .mainnavbar .navbar-nav > .nav-item.active > .nav-link,
.mainnav.navbar .mainnavbar .navbar-nav.menu-main > .nav-item.active > .nav-link {
  color: #ed6b06;
}
.mainnav.navbar .widget.phonenumber {
  order: 5;
  background-color: #ed6b06;
  color: #fff;
}
.mainnav.navbar .widget.phonenumber:hover, .mainnav.navbar .widget.phonenumber.open {
  background-color: rgb(212.1296296296, 95.7716049383, 5.3703703704);
}
.mainnav.navbar #account-widget {
  order: 3;
  border-left: 1px solid #edeae8;
}
.mainnav.navbar #account-widget .account-container .nav-item .avatar {
  border: 1px solid #edeae8;
}
.mainnav.navbar #account-widget .account-container .nav-item .nav-link {
  color: #444444;
}
.mainnav.navbar #account-widget .account-container .nav-item .nav-link:hover {
  color: #ed6b06;
}
.mainnav.navbar #account-widget .account-container .dropdown-menu {
  border: 1px solid #ccc;
  background-color: #f2f2f2;
}
.mainnav.navbar .widget.lang-picker {
  order: 4;
  border-left: 1px solid #edeae8;
}
.mainnav.navbar .widget.lang-picker .dropdown > .lang-active {
  color: #444444;
}
.mainnav.navbar .widget.lang-picker .dropdown > .lang-active:hover {
  text-decoration: none;
  color: #ed6b06;
}
.mainnav.navbar .widget.lang-picker .dropdown > .dropdown-menu {
  border: 1px solid #edeae8;
  background-color: #f2f2f2;
}
.mainnav.navbar .widget.lang-picker .dropdown > .dropdown-menu > .dropdown-item {
  color: #444444;
}
.mainnav.navbar .widget.lang-picker .dropdown > .dropdown-menu > .dropdown-item .nav-link {
  color: #444444;
}
.mainnav.navbar .widget.lang-picker .dropdown > .dropdown-menu > .dropdown-item.active {
  color: #ed6b06;
}
.mainnav.navbar .widget.lang-picker .dropdown > .dropdown-menu > .dropdown-item:hover .nav-link {
  color: #ed6b06;
}

#slider1,
#slider2,
#slider3 {
  background: #f4f4f4;
}
#slider1 .owl-nav [class*=owl-],
#slider2 .owl-nav [class*=owl-],
#slider3 .owl-nav [class*=owl-] {
  color: #444;
  background: transparent;
}
#slider1 .owl-nav [class*=owl-]:hover,
#slider2 .owl-nav [class*=owl-]:hover,
#slider3 .owl-nav [class*=owl-]:hover {
  background: rgba(0, 0, 0, 0);
  color: #444;
}
#slider1 .owl-dots .owl-dot span,
#slider2 .owl-dots .owl-dot span,
#slider3 .owl-dots .owl-dot span {
  border: 2px solid #444;
}
#slider1 .owl-dots .owl-dot.active span, #slider1 .owl-dots .owl-dot:hover span,
#slider2 .owl-dots .owl-dot.active span,
#slider2 .owl-dots .owl-dot:hover span,
#slider3 .owl-dots .owl-dot.active span,
#slider3 .owl-dots .owl-dot:hover span {
  background: #444;
  border: 2px solid #444;
}

#outer-slider1 {
  background-color: #f4f4f4;
}

#slider1 .slide {
  background-position: center center;
  background-attachment: fixed;
}

#slider1 .slide,
#slider2 .slide {
  background-color: #f4f4f4;
}
#slider1 .slide:before,
#slider2 .slide:before {
  background: unset;
}
#slider1 .slide .carouselcaption,
#slider2 .slide .carouselcaption {
  color: #444;
  background: transparent;
  margin: 0 auto;
  text-align: center;
}
#slider1 .slide .carouselcaption .sliderTitle,
#slider2 .slide .carouselcaption .sliderTitle {
  color: #fff;
}
#slider1 .slide .carouselcaption .sliderTitle h1,
#slider1 .slide .carouselcaption .sliderTitle h2,
#slider1 .slide .carouselcaption .sliderTitle h3,
#slider1 .slide .carouselcaption .sliderTitle h4,
#slider1 .slide .carouselcaption .sliderTitle h5,
#slider1 .slide .carouselcaption .sliderTitle h6,
#slider2 .slide .carouselcaption .sliderTitle h1,
#slider2 .slide .carouselcaption .sliderTitle h2,
#slider2 .slide .carouselcaption .sliderTitle h3,
#slider2 .slide .carouselcaption .sliderTitle h4,
#slider2 .slide .carouselcaption .sliderTitle h5,
#slider2 .slide .carouselcaption .sliderTitle h6 {
  color: #fff;
  text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);
}
#slider1 .slide .carouselcaption .sliderDesc,
#slider2 .slide .carouselcaption .sliderDesc {
  color: #fff;
}

#outer-slider2 {
  background-color: #f4f4f4;
}

#slider2 .slide .carouselcaption {
  color: #444;
  background: transparent;
}
#slider2 .slide .carouselcaption .sliderTitle {
  color: #fff;
}
#slider2 .slide .carouselcaption .sliderTitle h1,
#slider2 .slide .carouselcaption .sliderTitle h2,
#slider2 .slide .carouselcaption .sliderTitle h3,
#slider2 .slide .carouselcaption .sliderTitle h4,
#slider2 .slide .carouselcaption .sliderTitle h5,
#slider2 .slide .carouselcaption .sliderTitle h6 {
  color: #fff;
  text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);
}
#slider2 .slide .carouselcaption .sliderDesc {
  color: #fff;
}
#slider2 .owl-nav [class*=owl-] {
  color: #fff;
  background: transparent;
}
#slider2 .owl-nav [class*=owl-]:hover {
  background: rgba(0, 0, 0, 0);
  color: #fff;
}
#slider2 .owl-nav .owl-dots .owl-dot span {
  border: 2px solid #fff;
}
#slider2 .owl-nav .owl-dots .owl-dot.active span, #slider2 .owl-nav .owl-dots .owl-dot:hover span {
  background: #fff;
  border: 2px solid #fff;
}
#slider2 #slider3 .slide .carouselcaption {
  background: transparent;
}
#slider2 #slider3 .slide .carouselcaption .sliderTitle {
  color: #fff;
  color: #fff;
}
#slider2 #slider3 .slide .carouselcaption .sliderDesc {
  color: #fff;
}
#slider2 #slider3 .slide .carouselcaption .btn {
  color: #fff;
  background: #444;
}
#slider2 #slider3 .slide .carouselcaption .btn:hover {
  color: #fff;
  background: rgb(55.25, 55.25, 55.25);
}
#slider2 #slider3 .slide .carouselcaption .btn.btn-link {
  color: #fff;
  background: transparent;
}

.pageheaderimage {
  background-color: #f4f4f4;
}

.page24 .pageheaderimage,
.page23 .pageheaderimage,
.page34 .pageheaderimage,
.page5 .pageheaderimage,
.page22 .pageheaderimage,
.page35 .pageheaderimage,
.page23 .pageheaderimage,
.page49 .pageheaderimage {
  background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/diensten.jpg);
}

.page25 .pageheaderimage {
  background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/martin-fijn-ontmoeten.jpg);
  background-position: center;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 576px) {
  .page25 .pageheaderimage {
    background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/martin-fijn-ontmoeten.jpg);
    background-size: cover;
    background-position: 50% 23%;
  }
}

.page36 .pageheaderimage {
  background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/Martin%20foto%20laser.jpg);
  background-position: 66%;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 576px) {
  .page36 .pageheaderimage {
    background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/Martin%20foto%20laser.jpg);
    background-position: right;
  }
}

.page37 .pageheaderimage {
  background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/comingsoon_1.JPG);
  background-position-x: left;
  background-position-y: top;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 576px) {
  .page37 .pageheaderimage {
    background-position-x: 44%;
  }
}
@media (min-width: 992px) {
  .page37 .pageheaderimage {
    background-position-y: 30%;
  }
}

.page39 .pageheaderimage {
  background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/fundaBeoordelingen.jpg);
  background-position-y: bottom;
}

.page40 .pageheaderimage,
.page41 .pageheaderimage,
.page42 .pageheaderimage,
.page43 .pageheaderimage,
.page44 .pageheaderimage,
.page45 .pageheaderimage,
.page46 .pageheaderimage,
.page47 .pageheaderimage {
  background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/plaatselijkAanbod.jpg);
}

.quicksearch {
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  background-color: #f2f2f2;
}
.quicksearch .widget #realestateSearchTabs #realestateSearchDropdownMenuButton {
  border-color: #ced4da;
  background-color: #ffffff;
  color: #444444;
}
.quicksearch .widget #realestateSearchTabs #realestateSearchDropdownMenuButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.quicksearch .widget #query .fields .filter-container .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.quicksearch .widget #query .fields .filter-container .selectboxit-container.form-control .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #fff;
}
.quicksearch .widget #query .fields .filter-container .selectboxit-container.form-control .dropdown-menu .selectboxit-option .selectboxit-option-anchor {
  color: #212529;
  background-color: transparent;
}
.quicksearch .widget #query .fields .filter-container .selectboxit-container.form-control .dropdown-menu .selectboxit-option .selectboxit-option-anchor.active, .quicksearch .widget #query .fields .filter-container .selectboxit-container.form-control .dropdown-menu .selectboxit-option .selectboxit-option-anchor:focus, .quicksearch .widget #query .fields .filter-container .selectboxit-container.form-control .dropdown-menu .selectboxit-option .selectboxit-option-anchor:hover {
  color: #16181b;
  background-color: #f8f9fa;
}
.quicksearch .widget #query .fields .filter-container .selectboxit-container.form-control .dropdown-menu .selectboxit-option.selectboxit-selected .selectboxit-option-anchor {
  color: #fff;
  background-color: #ed6b06;
}
.quicksearch .widget #query .fields .filter-flex-container .flex.flex__input {
  background-color: #ffffff;
}
.quicksearch .widget #query .fields .filter-flex-container .flex.flex__input input, .quicksearch .widget #query .fields .filter-flex-container .flex.flex__input select {
  border-color: #ced4da;
}
.quicksearch .widget #query .fields .sys-search-suggestions {
  border-radius: 0;
  color: #444444;
  background: rgb(229.25, 229.25, 229.25);
}
.quicksearch .widget #query .fields .sys-search-suggestions a {
  color: #444444;
}
.quicksearch .widget #query .fields .sys-search-suggestions a:hover {
  color: #444444;
}
.quicksearch .widget #query .fields .select2-container.sys-location-filter.select2-container-active {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.quicksearch .widget #query .fields .select2-container.sys-location-filter .select2-choice {
  border-color: #ced4da;
}
.quicksearch .widget #query .fields .select2-container.sys-location-filter .select2-choice > .select2-chosen {
  color: #444444;
}
.quicksearch .widget #query .search-commands #btnSearch {
  background-color: #ed6b06;
  color: #ffffff;
  border-color: #ed6b06;
  font-weight: 400;
}
.quicksearch .widget #query .search-commands #btnSearch:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.quicksearch .widget #query .search-commands #btnSearch:hover {
  background-color: rgb(212.1296296296, 95.7716049383, 5.3703703704);
  border-color: rgb(212.1296296296, 95.7716049383, 5.3703703704);
}

#select2-drop {
  border-color: #ced4da;
}
#select2-drop .select2-search {
  background: #ffffff;
}
#select2-drop .select2-search input {
  font-size: 16px;
  color: #444444;
  border-color: #80bdff;
}
#select2-drop .select2-results .select2-no-results,
#select2-drop .select2-results .select2-searching {
  background: #ffffff;
}
#select2-drop .select2-results .select2-highlighted {
  background-color: #ed6b06;
  color: #ffffff;
}
#select2-drop .select2-results .select2-highlighted .select2-result-label .sys-location-item .sys-location-item-info .sys-location-item-description {
  color: #ffffff !important;
}

.selectboxit-container * {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

.searchresults .filter_buildable_area, .searchresults .filter_object_rooms,
.searchresults .filter_livable_area, .searchresults .filter_object_type,
.searchresults .filter_buildingPeriods, .searchresults .filter_object_properties,
.searchresults .filter_States, .searchresults .filter_object_properties, .searchresults .filter_object_characteristics,
.searchresults .filter_object_near_to {
  display: none;
}

.breadcrumb-container .breadcrumb .breadcrumb-item a {
  color: #444444;
}
.breadcrumb-container .breadcrumb .breadcrumb-item.active a {
  color: #ed6b06;
}
.breadcrumb-container .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #444444;
}

.fullwidth .breadcrumbs {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
}

.specials,
.recentlyviewed {
  background-color: #f2f2f2;
}
.specials .noslider,
.recentlyviewed .noslider {
  grid-template-columns: 1fr;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 576px) {
  .specials .noslider,
  .recentlyviewed .noslider {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .specials .noslider,
  .recentlyviewed .noslider {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.specials .owl-nav [class*=owl-],
.recentlyviewed .owl-nav [class*=owl-] {
  color: #444;
  background: transparent;
}
.specials .owl-nav [class*=owl-]:hover,
.recentlyviewed .owl-nav [class*=owl-]:hover {
  background: rgba(0, 0, 0, 0);
  color: #444;
}
.specials .object,
.recentlyviewed .object {
  border-radius: 0;
  background-color: #fff;
  color: #444;
}
.specials .object:before,
.recentlyviewed .object:before {
  background-color: #ed6b06;
}
.specials .object .object_status,
.recentlyviewed .object .object_status {
  border-radius: 0 0 0 0;
  background-color: rgba(255, 255, 255, 0.9);
  color: #ed6b06;
}
.specials .object .object_status.rented, .specials .object .object_status.sold,
.recentlyviewed .object .object_status.rented,
.recentlyviewed .object .object_status.sold {
  background-color: rgba(255, 255, 255, 0.9);
  color: #352874;
}
.specials .object .object_status.rented_under_conditions, .specials .object .object_status.sold_under_conditions,
.recentlyviewed .object .object_status.rented_under_conditions,
.recentlyviewed .object .object_status.sold_under_conditions {
  background-color: rgba(255, 255, 255, 0.9);
  color: #352874;
}
.specials .object .object_status.new_forsale, .specials .object .object_status.new_forrent,
.recentlyviewed .object .object_status.new_forsale,
.recentlyviewed .object .object_status.new_forrent {
  background-color: rgba(255, 255, 255, 0.9);
  color: #ed6b06;
}
.specials .object .object_status.price_changed,
.recentlyviewed .object .object_status.price_changed {
  background-color: rgba(52, 152, 219, 0.9);
  color: #ffffff;
}
.specials .object .object_status.open_house,
.recentlyviewed .object .object_status.open_house {
  background-color: rgba(237, 107, 6, 0.9);
  color: #ffffff;
}
.specials .object .img-container,
.recentlyviewed .object .img-container {
  border-radius: 0 0 0 0;
}
.specials .object .data,
.recentlyviewed .object .data {
  box-shadow: 0 2px 0 0 rgb(234.6, 234.6, 234.6);
}
.specials .object .data .obj_header .obj_price,
.recentlyviewed .object .data .obj_header .obj_price {
  color: #ed6b06;
}
.specials .object .data .obj_features,
.recentlyviewed .object .data .obj_features {
  border-top: 1px solid #f4f4f4;
}
.specials .object .data .obj_features > span,
.recentlyviewed .object .data .obj_features > span {
  border-right: 1px solid #f4f4f4;
}
.specials .object .data .obj_features > span i,
.recentlyviewed .object .data .obj_features > span i {
  color: #444;
}

.searchdetail .object_detail_contact img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
}

.searchdetail.berlin .wrapper .objectheader .mainphoto {
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (max-width: 768px) {
  .searchdetail.berlin .wrapper .objectheader .mainphoto {
    margin-bottom: 20px;
    height: 400px !important;
  }
}
@media (max-width: 576px) {
  .searchdetail.berlin .wrapper .objectheader .mainphoto {
    height: 200px !important;
  }
}
.searchdetail.berlin .wrapper .objectheader .mainphoto:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
}
.searchdetail.berlin .wrapper .realtorinfo-large {
  display: block;
}
.searchdetail.berlin .wrapper .realtorinfo-small {
  background-position: top;
}

#onlinebiedingpopup iframe {
  width: 100%;
  max-height: 100vh;
  min-height: 800px;
  border: none;
  margin: 0;
}
#onlinebiedingpopup iframe .thankyou {
  padding: 15px;
}

.berlin .openhouse pre {
  white-space: normal;
  line-height: 1.6;
}

.widget.employees {
  background-color: #fff;
}
.widget.employees .owl-nav [class*=owl-] {
  color: #444;
  background: transparent;
}
.widget.employees .owl-nav [class*=owl-]:hover {
  background: rgba(0, 0, 0, 0);
  color: #444;
}
.widget.employees .employeegrid .item {
  padding: 0;
  margin: 0 0 1rem;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
.widget.employees .employeegrid .item:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 6;
  background-color: #ed6b06;
  width: 0;
  height: 5px;
  content: "";
}
.widget.employees .employeegrid .item:hover {
  cursor: pointer;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.widget.employees .employeegrid .item:hover .employee-data-container {
  background: rgb(216.5, 216.5, 216.5);
}
.widget.employees .employeegrid .item:hover:before {
  width: 100%;
}
@media (min-width: 576px) {
  .widget.employees .employeegrid .item {
    margin-top: 0.5rem;
  }
}
.widget.employees .employeegrid .item .employee-image-container .employee-image img {
  object-position: 10% 10%;
  object-position: center;
}
.widget.employees .employeegrid .item .employee-image-container .employee-image .content.no-photo {
  background-color: #444;
  color: #fff;
}
.widget.employees .employeegrid .item .employee-image-container .employee-image:after {
  background: transparent;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data {
  color: #fff;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .moreinfo {
  width: 100%;
  height: 100%;
  color: transparent;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data:hover {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .employee-extra {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .employee-extra .bio,
.widget.employees .employeegrid .item .employee-image-container .employee-data .employee-extra .socials {
  display: none;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data a {
  color: #fff;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .socials .social_facebook {
  background-color: #365397;
  color: #ffffff;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .socials .social_twitter {
  background-color: #00a9f1;
  color: #ffffff;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .socials .social_linkedin {
  background-color: #006db3;
  color: #ffffff;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .socials .social_youtube {
  background-color: #ff3333;
  color: #ffffff;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .socials .social_googleplus {
  background-color: #e0452c;
  color: #ffffff;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .socials .social_pinterest {
  background-color: #ce1a19;
  color: #ffffff;
}
.widget.employees .employeegrid .item .employee-image-container .employee-data .socials .social_instagram {
  background-color: #c13584;
  color: #ffffff;
}
.widget.employees .employeegrid .item .employee-data-container {
  background: #f2f2f2;
  height: 55px;
}
.widget.employees .employeegrid .item .employee-data-container .employee-data {
  color: #fff;
  margin: 0;
}
.widget.employees .employeegrid .item .employee-data-container .employee-data .name {
  font-size: 0.8em;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  color: #ed6b06;
}
.widget.employees .employeegrid .item .employee-data-container .employee-data .role {
  display: none;
}

.contact-module {
  background-color: #f9f7f5;
}
.contact-module .search-fields,
.contact-module .contactplus,
.contact-module .contact-module-header {
  display: none !important;
}
.contact-module .grid .employee:hover .employee__image {
  transition: opacity 300ms ease-in-out 1s;
}
.contact-module .grid .employee,
.contact-module .grid .office,
.contact-module .grid .branch {
  background: #fff;
  box-shadow: 0 2px 0 0 #edeae8;
}
.contact-module .grid .employee:before,
.contact-module .grid .office:before,
.contact-module .grid .branch:before {
  background-color: #ed6b06;
}
.contact-module .grid .employee__data,
.contact-module .grid .office__data,
.contact-module .grid .branch__data {
  color: #444444;
}
.contact-module .grid .employee__footer,
.contact-module .grid .office__footer,
.contact-module .grid .branch__footer {
  border-top: 1px solid #f4f4f4;
}
.contact-module .grid .employee__footer .listings,
.contact-module .grid .office__footer .listings,
.contact-module .grid .branch__footer .listings {
  color: #352874;
}
.contact-module .grid .employee__footer .listings:hover,
.contact-module .grid .office__footer .listings:hover,
.contact-module .grid .branch__footer .listings:hover {
  color: rgb(35.6730769231, 26.9230769231, 78.0769230769);
}
.contact-module .employee-detail__header,
.contact-module .office-detail__header,
.contact-module .branch-detail__header {
  background-color: #fff;
  box-shadow: 0 2px 0 0 #edeae8;
  margin-bottom: 3rem;
}
.contact-module .employee-detail__header .image.image-object-fit,
.contact-module .office-detail__header .image.image-object-fit,
.contact-module .branch-detail__header .image.image-object-fit {
  background-color: #f2f2f2;
  order: 0;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 992px) {
  .contact-module .employee-detail__header .image.image-object-fit,
  .contact-module .office-detail__header .image.image-object-fit,
  .contact-module .branch-detail__header .image.image-object-fit {
    width: 30%;
    min-height: 842px;
  }
}
.contact-module .employee-detail__header .image.image-object-fit img,
.contact-module .office-detail__header .image.image-object-fit img,
.contact-module .branch-detail__header .image.image-object-fit img {
  opacity: 1;
  transition: opacity 0.5s;
  transition-delay: 1.2s;
}
.contact-module .employee-detail__header .data,
.contact-module .office-detail__header .data,
.contact-module .branch-detail__header .data {
  order: 1;
}
.contact-module .employee-detail__header .data .employee_function,
.contact-module .office-detail__header .data .employee_function,
.contact-module .branch-detail__header .data .employee_function {
  word-break: break-all;
}
.contact-module .employee-detail__header .data .employee__name strong,
.contact-module .employee-detail__header .data .mainoffice,
.contact-module .employee-detail__header .data .email,
.contact-module .employee-detail__header .data .phone,
.contact-module .office-detail__header .data .employee__name strong,
.contact-module .office-detail__header .data .mainoffice,
.contact-module .office-detail__header .data .email,
.contact-module .office-detail__header .data .phone,
.contact-module .branch-detail__header .data .employee__name strong,
.contact-module .branch-detail__header .data .mainoffice,
.contact-module .branch-detail__header .data .email,
.contact-module .branch-detail__header .data .phone {
  color: #ed6b06;
}
.contact-module .employee-detail__header .data .social,
.contact-module .office-detail__header .data .social,
.contact-module .branch-detail__header .data .social {
  border: 1px solid #444;
  color: #444;
}
.contact-module .employee-detail__header .data .social__facebook,
.contact-module .office-detail__header .data .social__facebook,
.contact-module .branch-detail__header .data .social__facebook {
  border-color: #365397;
  color: #365397;
}
.contact-module .employee-detail__header .data .social__facebook:hover,
.contact-module .office-detail__header .data .social__facebook:hover,
.contact-module .branch-detail__header .data .social__facebook:hover {
  color: #fff;
  background-color: #365397;
  border-color: #365397;
}
.contact-module .employee-detail__header .data .social__twitter,
.contact-module .office-detail__header .data .social__twitter,
.contact-module .branch-detail__header .data .social__twitter {
  border-color: #00a9f1;
  color: #00a9f1;
}
.contact-module .employee-detail__header .data .social__twitter:hover,
.contact-module .office-detail__header .data .social__twitter:hover,
.contact-module .branch-detail__header .data .social__twitter:hover {
  color: #fff;
  background-color: #00a9f1;
  border-color: #00a9f1;
}
.contact-module .employee-detail__header .data .social__linkedin,
.contact-module .office-detail__header .data .social__linkedin,
.contact-module .branch-detail__header .data .social__linkedin {
  border-color: #006db3;
  color: #006db3;
}
.contact-module .employee-detail__header .data .social__linkedin:hover,
.contact-module .office-detail__header .data .social__linkedin:hover,
.contact-module .branch-detail__header .data .social__linkedin:hover {
  color: #fff;
  background-color: #006db3;
  border-color: #006db3;
}
.contact-module .employee-detail__header .data .social__youtube,
.contact-module .office-detail__header .data .social__youtube,
.contact-module .branch-detail__header .data .social__youtube {
  border-color: #ff3333;
  color: #ff3333;
}
.contact-module .employee-detail__header .data .social__youtube:hover,
.contact-module .office-detail__header .data .social__youtube:hover,
.contact-module .branch-detail__header .data .social__youtube:hover {
  color: #fff;
  background-color: #ff3333;
  border-color: #ff3333;
}
.contact-module .employee-detail__header .data .social__googleplus,
.contact-module .office-detail__header .data .social__googleplus,
.contact-module .branch-detail__header .data .social__googleplus {
  border-color: #e0452c;
  color: #e0452c;
}
.contact-module .employee-detail__header .data .social__googleplus:hover,
.contact-module .office-detail__header .data .social__googleplus:hover,
.contact-module .branch-detail__header .data .social__googleplus:hover {
  color: #fff;
  background-color: #e0452c;
  border-color: #e0452c;
}
.contact-module .employee-detail__header .data .social__instagram,
.contact-module .office-detail__header .data .social__instagram,
.contact-module .branch-detail__header .data .social__instagram {
  border-color: #c13584;
  color: #c13584;
}
.contact-module .employee-detail__header .data .social__instagram:hover,
.contact-module .office-detail__header .data .social__instagram:hover,
.contact-module .branch-detail__header .data .social__instagram:hover {
  color: #fff;
  background-color: #c13584;
  border-color: #c13584;
}
.contact-module .employee-detail__header .data .social__pinterest,
.contact-module .office-detail__header .data .social__pinterest,
.contact-module .branch-detail__header .data .social__pinterest {
  border-color: #ce1a19;
  color: #ce1a19;
}
.contact-module .employee-detail__header .data .social__pinterest:hover,
.contact-module .office-detail__header .data .social__pinterest:hover,
.contact-module .branch-detail__header .data .social__pinterest:hover {
  color: #fff;
  background-color: #ce1a19;
  border-color: #ce1a19;
}
.contact-module .employee-detail__text,
.contact-module .office-detail__text,
.contact-module .branch-detail__text {
  border-bottom: unset;
  padding: 2rem 0;
  box-shadow: unset;
}
.contact-module .employee-detail__properties .specials,
.contact-module .office-detail__properties .specials,
.contact-module .branch-detail__properties .specials {
  background-color: #f9f7f5;
}

.page26 .image-object-fit img {
  object-position: top;
}
.page26 .image-object-fit img[src*="https://hayweb.blob.core.windows.net/public/p6qr1j/1/20210716_Zopfi_054Compressed.jpg"] {
  object-position: 65% top;
}

.news.widget {
  background-color: #f9f7f5;
}
.news.widget .flex-row .item a {
  background-color: #fff;
  box-shadow: 0 2px 0 0 #edeae8;
  border-radius: 0;
}
.news.widget .flex-row .item a:before {
  background-color: #ed6b06;
}
.news.widget .flex-row .item a .image {
  border-radius: 0 0 0 0;
}
.news.widget .flex-row .item a .image .info span {
  background-color: #fff;
  color: #444;
  box-shadow: 0 1px 6px rgba(61, 65, 84, 0.15);
  border-radius: 0;
}
.news.widget .flex-row .item a .text {
  color: #444444;
}
.news.widget .flex-row .item a .text h3 {
  word-break: normal;
}
.news.widget .flex-row .item a .footer {
  border-top: 1px solid #f4f4f4;
}
.news.widget .flex-row .item a .footer .date {
  color: #6d6d6d;
}
.news.newslist .flex .item a {
  border-radius: 0;
  box-shadow: 0 2px 0 0 #edeae8;
  background-color: #fff;
}
.news.newslist .flex .item a:before {
  background-color: #ed6b06;
}
.news.newslist .flex .item a .image {
  border-radius: 0 0 0 0;
}
.news.newslist .flex .item a .image .info span {
  background-color: #fff;
  color: #444;
  box-shadow: 0 1px 6px rgba(61, 65, 84, 0.15);
  border-radius: 0;
}
.news.newslist .flex .item a .text {
  color: #444444;
}
.news.newslist .flex .item a .text h3 {
  word-break: normal;
}
.news.newslist .flex .item a .footer {
  border-top: 1px solid #f4f4f4;
}
.news.newslist .flex .item a .footer .date {
  color: #6d6d6d;
}

.newspage .main {
  background-color: #f9f7f5;
  padding: 3rem 0;
}

.newsdetailpage {
  background-color: #f9f7f5;
}
.newsdetailpage .wrapper {
  padding-top: 0;
}
.newsdetailpage .news.newsitem .pageheader {
  background: url(https://hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/diensten.jpg);
  background-size: cover !important;
  background-position: center;
  height: 400px;
  background-color: #f4f4f4;
}
.newsdetailpage .news.newsitem .pageheader .content {
  opacity: 0;
}
.newsdetailpage .news.newsitem .newscontent {
  background-color: #fff;
}
.newsdetailpage .news.newsitem .newscontent .imgArticle {
  min-height: 100px;
  max-height: 250px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.newsdetailpage .news.newsitem .newscontent .imgArticle img {
  width: 100%;
}
.newsdetailpage .news.newsitem .footer {
  border-top: 1px solid #f4f4f4;
  box-shadow: 0 2px 0 0 #edeae8;
  background-color: #fff;
}
.newsdetailpage .news.newsitem .footer .share_options_website .btn {
  border-radius: 0 !important;
}
.newsdetailpage .news.newsitem .footer .share_options_website .btn.addthis_button_facebook {
  background: #365397;
  color: #fff;
}
.newsdetailpage .news.newsitem .footer .share_options_website .btn.addthis_button_facebook:hover {
  background: rgb(40.5658536585, 62.3512195122, 113.4341463415);
}
.newsdetailpage .news.newsitem .footer .share_options_website .btn.addthis_button_whatsapp {
  background: #25d366;
  color: #fff;
}
.newsdetailpage .news.newsitem .footer .share_options_website .btn.addthis_button_whatsapp:hover {
  background: rgb(29.3911290323, 167.6088709677, 81.0241935484);
}
.newsdetailpage .news.newsitem .footer .share_options_website .btn.addthis_button_twitter {
  background: #00a9f1;
  color: #fff;
}
.newsdetailpage .news.newsitem .footer .share_options_website .btn.addthis_button_twitter:hover {
  background: rgb(0, 133.2365145228, 190);
}

.widget.branchelogos {
  background: #fff;
}
.widget.branchelogos .owl-nav {
  display: none;
}
.widget.branchelogos .owl-nav [class*=owl-] {
  color: #444;
  background: #444;
}
.widget.branchelogos .owl-nav [class*=owl-]:hover {
  background: rgba(0, 0, 0, 0);
  color: #444;
}
.widget.branchelogos .owl-dots .owl-dot span {
  border: 2px solid #444;
}
.widget.branchelogos .owl-dots .owl-dot.active span, .widget.branchelogos .owl-dots .owl-dot:hover span {
  background: #444;
  border: 2px solid #444;
}

.company__details .widget.social .social {
  border: 1px solid #fff;
  color: #fff;
}
.company__details .widget.social .social:hover {
  text-decoration: none;
}

.widget.social .social {
  border: 1px solid #444;
  color: #444;
  /* Convert Google+ to Instagram */
}
.widget.social .social:hover {
  text-decoration: none;
}
.widget.social .social.social_facebook:hover {
  color: #fff;
  background-color: #365397;
  border-color: #365397;
}
.widget.social .social.social_twitter:hover {
  color: #fff;
  background-color: #00a9f1;
  border-color: #00a9f1;
}
.widget.social .social.social_linkedin:hover {
  color: #fff;
  background-color: #006db3;
  border-color: #006db3;
}
.widget.social .social.social_youtube:hover {
  color: #fff;
  background-color: #ff3333;
  border-color: #ff3333;
}
.widget.social .social.social_googleplus i:before {
  content: "\f16d";
}
.widget.social .social.social_googleplus:hover {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  opacity: 0.8;
}
.widget.social .social.social_instagram:hover {
  color: #fff;
  background-color: #c13584;
  border-color: #c13584;
}
.widget.social .social.social_pinterest:hover {
  color: #fff;
  background-color: #ce1a19;
  border-color: #ce1a19;
}

.recentlyviewed {
  background-color: rgba(237, 107, 6, 0.04);
}
.recentlyviewed .intro h2 {
  color: #444444;
}
.recentlyviewed .recentlyviewed-carousel .object {
  background-color: #fff;
}
.recentlyviewed .recentlyviewed-carousel .object .sys-project-type.full-width {
  background-color: rgb(229.5, 229.5, 229.5);
}

.module-testimonials .module-testimonials-content .testimonial {
  background-color: #f2f2f1;
}
.module-testimonials .module-testimonials-content .testimonial .testimonial-header .testimonial-title {
  color: #ed6b06;
}
.module-testimonials .module-testimonials-content .testimonial .testimonial-header .testimonial-location {
  color: #444444;
}
.module-testimonials .module-testimonials-content .testimonial .testimonial-content .testimonial-contact-name {
  font-family: "Indie Flower", cursive;
}

.page .textblock7 {
  background-color: #f9f7f5;
  padding: 60px 15px;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 1200px) {
  .page .textblock7 {
    padding: 100px 15px;
  }
}
.page .textblock7 > .container {
  max-width: 1190px;
  background-color: #fff;
  box-shadow: 0 2px 0 0 #edeae8;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  padding: 0;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 768px) {
  .page .textblock7 > .container {
    flex-flow: row nowrap;
  }
}
.page .textblock7 .text {
  order: 1;
  padding: 20px;
  color: #444444;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 768px) {
  .page .textblock7 .text {
    order: 0;
    padding: 60px;
  }
}
.page .textblock7 .image {
  flex-shrink: 0;
  order: 0;
  width: 100%;
  position: relative;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
.page .textblock7 .image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.page .textblock7 .image > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.page .textblock7 .image > .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .page .textblock7 .image {
    width: 50%;
    order: 1;
  }
}
.page .textblock8 {
  display: flex;
  position: relative;
  align-items: center;
  background-color: rgb(94.8, 42.8, 2.4);
  padding: 60px 0 calc(60px - 1rem) !important;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
  text-align: center;
}
@media (min-width: 1200px) {
  .page .textblock8 {
    padding: 100px 0 calc(100px - 1rem) !important;
  }
}
.page .textblock8 .text {
  position: relative;
  z-index: 2;
  color: #fff;
}
.page .textblock8 .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.page .textblock8 .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page24 .col-lg.main {
  padding-bottom: 0;
}
.page39 iframe {
  max-width: 975px;
  margin-left: -15px;
}

.grid-cards .card {
  box-shadow: 0 2px 0 0 #edeae8;
  background-color: #fff;
}
.grid-cards .card__content .btn {
  margin-top: 1rem;
  border: 1px solid #fff;
  color: #fff;
}
.grid-cards .card__content .btn:hover {
  background-color: #fff;
  color: #ed6b06;
}
.grid-cards .card__funda {
  background-color: #925b00;
  color: #fff;
  box-shadow: 0 2px 0 0 rgb(105.2, 65.5698630137, 0);
}
.grid-cards .card__funda img {
  max-width: 80px;
}
.grid-cards .card__funda .btn:hover {
  background-color: #fff;
  color: #925b00;
}
.grid-cards .card-primary {
  box-shadow: 0 2px 0 0 rgb(197.2074074074, 89.0345679012, 4.9925925926);
  background-color: #ed6b06;
  color: #fff;
}
.grid-cards .card-success {
  box-shadow: 0 2px 0 0 rgb(197.2074074074, 89.0345679012, 4.9925925926);
  background-color: #ed6b06;
  color: #fff;
}
.grid-cards .card-info {
  box-shadow: 0 2px 0 0 rgb(18.4666666667, 130.0695652174, 147.7333333333);
  background-color: #17a2b8;
  color: #fff;
}
.grid-cards .card-warning {
  box-shadow: 0 2px 0 0 rgb(201.5774193548, 123.0870967742, 19.6225806452);
  background-color: #e9911d;
  color: #fff;
}
.grid-cards .card-danger {
  box-shadow: 0 2px 0 0 rgb(197.9088607595, 34.2911392405, 49.9670886076);
  background-color: #dc3545;
  color: #fff;
}

.objectgrid {
  margin: 3rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 576px) {
  .objectgrid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .objectgrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.objectgrid .object {
  overflow: hidden;
  display: block;
  border: 1px solid #ccc;
  position: relative;
}
.objectgrid .object:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.objectgrid .object > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.objectgrid .object > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 500ms ease-in-out;
}
.objectgrid .object .status {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12.8px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #ed6b06;
  padding: 8px 16px;
  pointer-events: none;
  font-weight: bold;
  z-index: 5;
  text-align: center;
}
.objectgrid .object > .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  padding: 15px;
  overflow: hidden;
}
.objectgrid .object > .content .street {
  font-size: 1em;
  display: block;
  font-weight: bold;
  color: #fff;
  margin: 0;
}
.objectgrid .object > .content .city {
  font-size: 1em;
  display: block;
  color: #fff;
  text-shadow: 0 0 10px #000;
  margin-bottom: 0.4em;
}
.objectgrid .object > .content .city.last {
  text-shadow: none;
}
.objectgrid .object:hover img {
  transform: scale(1.2);
}

.home .main-content {
  display: none;
}
.home .text-block7 .container {
  padding: 2rem;
}

.berlin .object .object_status {
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: #ed6b06 !important;
}
.berlin .object .object_status.sold, .berlin .object .object_status.sold_under_conditions {
  color: #352874 !important;
}

.page11 .heading p {
  display: none;
}

.c2a_video {
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
  display: flex;
  justify-content: space-between;
}
@media (min-width: 576px) {
  .c2a_video {
    margin-top: unset;
  }
}
.c2a_video .arrowImg {
  pointer-events: none;
  position: relative;
  top: -100px;
  width: 90px;
}

.companypage {
  background-color: #f9f7f5;
}
.companypage .company__pageheader {
  background-color: #333;
}
.companypage .company__map .mapboxgl-marker svg g {
  fill: #ed6b06;
}
.companypage .company__container {
  box-shadow: 0 2px 0 0 #edeae8;
  background-color: #fff;
}
.companypage .company__container .article-title {
  color: #fff;
}
.companypage .company__details {
  padding: 30px;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
  background: #352874;
  color: #fff;
}
@media (min-width: 576px) {
  .companypage .company__details {
    padding: 60px;
  }
}
.companypage .company__details a {
  color: #fff;
}

.customer-testimonials .main-article .number {
  color: #ed6b06;
}
.customer-testimonials .module-testimonials-content .testimonial-container .testimonial {
  background-color: #f2f2f1;
}
.customer-testimonials .module-testimonials-content .testimonial-container .testimonial .avatarcontainer .rating {
  background-color: #fff;
  color: #ed6b06;
}
.customer-testimonials .module-testimonials-content .testimonial-container .testimonial .author {
  color: #ed6b06;
}

.websiteFooter {
  background-color: #f2f2f2;
  color: #444;
}
.websiteFooter .footerWidgets .col.col-lg-3 {
  text-align: center;
  max-width: 100%;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
.websiteFooter .footerWidgets #menu-3 {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (min-width: 1200px) {
  .websiteFooter .footerWidgets #menu-3 {
    flex-direction: row;
  }
}
.websiteFooter .footerWidgets #menu-3 .widgetTitle {
  text-align: center;
}
.websiteFooter .footerWidgets #menu-3 ul {
  align-items: center;
}
.websiteFooter .footerWidgets #menu-3 .col.inactive.level-1.type-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.websiteFooter .footerWidgets #menu-3 .col.inactive.level-1.type-text ul {
  align-items: center;
}
.websiteFooter .footerWidgets div.col.footerMenu:last-of-type {
  max-width: 100%;
  justify-content: center;
  padding: 0;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 576px) {
  .websiteFooter .footerWidgets div.col.footerMenu:last-of-type {
    max-width: 25%;
    margin: 0 auto;
  }
}
.websiteFooter .footerWidgets div.col.footerMenu:last-of-type .openingsTijden {
  text-align: center;
  max-width: 100%;
  /* max 576px */
  /* max 576px */
  /* max 576px */
  /* 576px */
}
@media (min-width: 576px) {
  .websiteFooter .footerWidgets div.col.footerMenu:last-of-type .openingsTijden {
    max-width: fit-content;
  }
}
.websiteFooter .footerWidgets div.col.footerMenu:last-of-type .openingsTijden .flex_tijden {
  display: flex;
  width: 220px;
  justify-content: space-between;
}
.websiteFooter .footerWidgets .widgetTitle {
  color: #444;
}
.websiteFooter .footerWidgets .widget.company .d-flex {
  justify-content: center;
}
.websiteFooter .footerWidgets .widget.company i {
  color: #ed6b06;
}
.websiteFooter .footerWidgets .widget.company .company.name {
  color: #444;
}
.websiteFooter .footerWidgets .widget.company a {
  color: #444;
}
.websiteFooter .footerWidgets .widget.company a:hover {
  color: #ed6b06;
}
.websiteFooter .footerWidgets .widget.offices .widgetContent .office .branchName .branchName,
.websiteFooter .footerWidgets .widget.offices .widgetContent .office .branchName .officeName,
.websiteFooter .footerWidgets .widget.offices .widgetContent .office .officeName .branchName,
.websiteFooter .footerWidgets .widget.offices .widgetContent .office .officeName .officeName,
.websiteFooter .footerWidgets .widget.offices .widgetContent .branch .branchName .branchName,
.websiteFooter .footerWidgets .widget.offices .widgetContent .branch .branchName .officeName,
.websiteFooter .footerWidgets .widget.offices .widgetContent .branch .officeName .branchName,
.websiteFooter .footerWidgets .widget.offices .widgetContent .branch .officeName .officeName,
.websiteFooter .footerWidgets .widget.branches .widgetContent .office .branchName .branchName,
.websiteFooter .footerWidgets .widget.branches .widgetContent .office .branchName .officeName,
.websiteFooter .footerWidgets .widget.branches .widgetContent .office .officeName .branchName,
.websiteFooter .footerWidgets .widget.branches .widgetContent .office .officeName .officeName,
.websiteFooter .footerWidgets .widget.branches .widgetContent .branch .branchName .branchName,
.websiteFooter .footerWidgets .widget.branches .widgetContent .branch .branchName .officeName,
.websiteFooter .footerWidgets .widget.branches .widgetContent .branch .officeName .branchName,
.websiteFooter .footerWidgets .widget.branches .widgetContent .branch .officeName .officeName {
  color: #444;
}
.websiteFooter .footerWidgets .widget.offices .widgetContent .office a,
.websiteFooter .footerWidgets .widget.offices .widgetContent .branch a,
.websiteFooter .footerWidgets .widget.branches .widgetContent .office a,
.websiteFooter .footerWidgets .widget.branches .widgetContent .branch a {
  color: #444;
}
.websiteFooter .footerWidgets .widget.offices .widgetContent .office a i,
.websiteFooter .footerWidgets .widget.offices .widgetContent .branch a i,
.websiteFooter .footerWidgets .widget.branches .widgetContent .office a i,
.websiteFooter .footerWidgets .widget.branches .widgetContent .branch a i {
  color: #ed6b06;
}
.websiteFooter .footerWidgets .widget.offices .widgetContent .office a:hover,
.websiteFooter .footerWidgets .widget.offices .widgetContent .branch a:hover,
.websiteFooter .footerWidgets .widget.branches .widgetContent .office a:hover,
.websiteFooter .footerWidgets .widget.branches .widgetContent .branch a:hover {
  color: #ed6b06;
}
.websiteFooter .footerWidgets .widget.offices .widgetContent .office .officeFax i,
.websiteFooter .footerWidgets .widget.offices .widgetContent .branch .officeFax i,
.websiteFooter .footerWidgets .widget.branches .widgetContent .office .officeFax i,
.websiteFooter .footerWidgets .widget.branches .widgetContent .branch .officeFax i {
  color: #ed6b06;
}
.websiteFooter .footerWidgets .widget.offices.minimal ul li a,
.websiteFooter .footerWidgets .widget.branches.minimal ul li a {
  color: #444;
}
.websiteFooter .footerWidgets .widget.offices.minimal ul li a i,
.websiteFooter .footerWidgets .widget.branches.minimal ul li a i {
  color: #ed6b06;
}
.websiteFooter .footerWidgets .widget.offices.minimal ul li a:hover,
.websiteFooter .footerWidgets .widget.branches.minimal ul li a:hover {
  color: #ed6b06;
}
.websiteFooter .footerWidgets .footerMenu ul li a {
  color: #444;
}
.websiteFooter .footerWidgets .footerMenu ul li a i {
  color: #ed6b06;
}
.websiteFooter .footerWidgets .footerMenu ul li a:hover {
  color: #ed6b06;
}
.websiteFooter .footerBottom {
  border-top: 1px solid #c7c7c7;
}
.websiteFooter .footerBottom .copyright {
  color: #444;
}
.websiteFooter .footerBottom .subfooterMenu > li > a {
  border-bottom: 1px solid #444;
  color: #444;
}
.websiteFooter .footerBottom .subfooterMenu > li > a:hover {
  border-bottom: 1px solid #ed6b06;
  color: #ed6b06;
}

div.footerWidgets div:nth-child(1) {
  order: 1;
}

.vbo_beeldmerk {
  display: block;
  height: auto;
  width: 5rem;
}

.widget.whatsapp {
  order: 6;
}
.widget.whatsapp .whatsapp__button {
  background-color: #25d366;
  color: #fff;
}
.widget.whatsapp .whatsapp__button:hover, .widget.whatsapp .whatsapp__button.open {
  background-color: rgb(33.1955645161, 189.3044354839, 91.5120967742);
}

.clearcache .cache-confirmation .icon {
  border: 1px solid #bbb;
  background: #fbfbfb;
}
.clearcache .cache-confirmation .linkiconref {
  color: #999;
}

.alert-promo {
  border: 1px solid #ed6b06;
  color: #ffffff;
  background: #ed6b06;
}
.alert-promo__close {
  color: #ffffff;
}
.alert-promo__close:hover {
  color: #ffffff;
}