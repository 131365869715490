.companypage {
    background-color: $module-contact-bgColor;

    .company {
        &__pageheader {
            background-color: $module-contact-pageheader-color;                            
        }

        &__map {
            .mapboxgl-marker {
                svg {
                    g {
                        fill: $module-contact-mapmarker-color;
                    }
                }
            }
        }

        &__container {            
            box-shadow: $module-contact-block-boxShadow;
            background-color: $module-contact-block-text-bgColor;
            .article-title {
                color: #fff;            
            }
        }

        &__details {
            padding: 30px;
            @include respond-to(sm){
                padding: 60px;
            }
            background: $brand-color-secondary;
            color: $module-contact-block-color;

            a {
                color: $module-contact-block-color;
            }
        }        
    }
}
