.widget.whatsapp {
  order: $header-order-whatsapp;

  .whatsapp {
    &__button {
      background-color: $whatsapp-color;
      color: #fff;

      &:hover,
      &.open {
        background-color: darken($whatsapp-color, 5%);
      }
    }    
  }
}