.quicksearch {
    border-top: 1px solid $widget-quicksearch-borderColor;
    border-bottom: 1px solid $widget-quicksearch-borderColor;
    background-color: $widget-quicksearch-bgColor;
    .widget {
        #realestateSearchTabs {
            #realestateSearchDropdownMenuButton {
                border-color: $widget-quicksearch-input-borderColor;
                background-color: $widget-quicksearch-input-bgColor;
                color: $font-base-color;
                &:focus {
                    box-shadow: $widget-quicksearch-formControlActive;
                }
            }
        }
        #query {
            .fields {
                .filter-container {
                    .form-control {
                        &:focus {
                            box-shadow: $formControlActive;
                        }
                    }
                    .selectboxit-container.form-control {
                        .dropdown-menu {
                            border: 1px solid $widget-quicksearch-dropdown-borderColor;
                            background-color: $widget-quicksearch-dropdown-bgColor;
                            color: $widget-quicksearch-dropdown-color;

                            .selectboxit-option {
                                .selectboxit-option-anchor {
                                    color: $widget-quicksearch-dropdown-item-color;
                                    background-color: $widget-quicksearch-dropdown-item-bgColor;
                                    &.active, &:focus, &:hover {
                                        color: $widget-quicksearch-dropdown-item-hover-color;
                                        background-color: $widget-quicksearch-dropdown-item-hover-bgColor;
                                    }
                                }

                                &.selectboxit-selected {
                                    .selectboxit-option-anchor {
                                        color: $widget-quicksearch-dropdown-item-selected-color;                                        
                                        background-color: $widget-quicksearch-dropdown-item-selected-bgColor;
                                    }
                                }
                            }
                        }
                    }
                }

                .filter-flex-container {
                    .flex {
                        &.flex__input {
                            background-color: $widget-quicksearch-input-bgColor;

                            input, select {
                                border-color: $widget-quicksearch-input-borderColor;
                            }
                        }                                                
                    }                    
                }                
                .sys-search-suggestions {
                    border-radius: $border-radius-large;
                    color: $widget-quicksearch-tab-color;
                    background: darken($widget-quicksearch-bgColor, 5%);

                    a {
                        color: $widget-quicksearch-tab-color;

                        &:hover {
                            color: $widget-quicksearch-tab-color;
                        }
                    }
                }

                .select2-container.sys-location-filter {
                    &.select2-container-active {
                        box-shadow: $formControlActive;
                    }

                    .select2-choice {
                        border-color: $widget-quicksearch-input-borderColor;

                        > .select2-chosen {
                            color: $font-base-color;
                        }
                    }                    
                }
            }

            .search-commands {
                #btnSearch {
                    background-color: $widget-quicksearch-searchbtn-bgColor;
                    color: $widget-quicksearch-searchbtn-color;
                    border-color: $widget-quicksearch-searchbtn-bgColor;
                    font-weight: $widget-quicksearch-btn-font-weight;

                    &:focus {
                        box-shadow: $formControlActive;
                    }

                    &:hover {
                        background-color: darken($widget-quicksearch-searchbtn-bgColor, 5%);
                        border-color: darken($widget-quicksearch-searchbtn-bgColor, 5%);
                    }
                }
            }
        }
    }
}

#select2-drop {
    border-color: $widget-quicksearch-input-borderColor;

    .select2-search {
        background: $widget-quicksearch-select2-input-bgColor;
        input {
            font-size: $font-base-size;
            color: $widget-quicksearch-select2-input-color;
            border-color: #80bdff;
        }
    }

    .select2-results {
        .select2-no-results,
        .select2-searching {
            background: $widget-quicksearch-select2-input-bgColor;
        }

        .select2-highlighted {
            background-color: $widget-quicksearch-select2-item-hover-bgColor;
            color: $widget-quicksearch-select2-item-hover-color;

            .select2-result-label {
                .sys-location-item {
                    .sys-location-item-info {
                        .sys-location-item-description {
                            color: $widget-quicksearch-select2-item-hover-color !important;
                        }
                    }
                }
            }
        }
    }
}

.selectboxit-container * {
    font-family: $font-family-body;
    font-size: $font-base-size;
}

// filters wegstijlen
.searchresults {
	.filter_buildable_area, .filter_object_rooms, 
    .filter_livable_area, .filter_object_type,
     .filter_buildingPeriods, .filter_object_properties,
    .filter_States, .filter_object_properties, .filter_object_characteristics,
    .filter_object_near_to {
		display: none;
	  }
 }
//