﻿
.alert-promo {
    border: 1px solid $widget-alert-promo-bgcolor;
    color: $widget-alert-promo-color;
    background: $widget-alert-promo-bgcolor;
    &__close {
        color: $widget-alert-promo-color;
        &:hover {
            color: $widget-alert-promo-color;
        }
    }
}