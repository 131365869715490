.widget.employees {
  background-color: $widget-employees-bgcolor;

  .owl-nav {
    [class*="owl-"] {
      color: $widget-employees-slider-nav-color;
      background: $widget-employees-slider-nav-bgcolor;

      &:hover {
        background: darken($widget-employees-slider-nav-bgcolor, 5%);
        color: $widget-employees-slider-nav-color;
      }
    }
  }


  .employeegrid {
    .item {
      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        z-index: 6;
        background-color: $brand-color;
        width: 0;
        height: 5px;
        content: "";
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;

        .employee-data-container {
          background: darken(#f2f2f2, 10%);
        }

        &:before {
          width: 100%;
        }
      }

      padding:0;
      margin: 0 0 1rem;

      @include respond-to('sm') {
        margin-top: .5rem;
      }

      .employee-image-container {
        .employee-image {
          img {
            object-position: 10% 10%;
            object-position: center;
          }

          .content {
            &.no-photo {
              background-color: $widget-employees-item-nophoto-bgcolor;
              color: $widget-employees-item-nophoto-color;
            }
          }

          &:after {
            background: transparent;
          }
        }

        .employee-data {
          .moreinfo {
            width: 100%;
            height: 100%;
            color: transparent;

          }

          &:hover {
            opacity: 1;
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
          }

          .employee-extra {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;

            .bio,
            .socials {
              display: none;
            }

          }

          color: $widget-employees-item-color;

          a {
            color: $widget-employees-item-color;
          }

          .socials {
            .social {
              &_facebook {
                background-color: $facebook_color;
                color: $color_white;
              }

              &_twitter {
                background-color: $twitter_color;
                color: $color_white;
              }

              &_linkedin {
                background-color: $linkedin_color;
                color: $color_white;
              }

              &_youtube {
                background-color: $youtube_color;
                color: $color_white;
              }

              &_googleplus {
                background-color: $google_plus_color;
                color: $color_white;
              }

              &_pinterest {
                background-color: $pinterest_color;
                color: $color_white;
              }

              &_instagram {
                background-color: $instagram_color;
                color: $color_white;
              }
            }
          }
        }
      }

      .employee-data-container {
        background: #f2f2f2;
        height: 55px;

        .employee-data {
          color: #fff;
          margin: 0;

          .name {
            font-size: 0.8em;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 0.15rem;
            color: $brand-color;
          }

          .role {
            display: none;
          }
        }
      }
    }
  }
}

.contact-module {
  background-color: $module-contactplus-bgcolor;

  .search-fields,
  .contactplus,
  .contact-module-header {
    display: none !important;
  }

  .grid {
    .employee:hover {
      .employee__image {
        transition: opacity 300ms ease-in-out 1s;
      }
    }

    .employee,
    .office,
    .branch {
      background: $module-contactplus-item-bgcolor;
      box-shadow: $module-contactplus-item-boxshadow;

      &:before {
        background-color: $module-contactplus-item-hover-border;
      }

      &__data {
        color: $module-contactplus-item-data-color;
      }

      &__footer {
        border-top: 1px solid $module-contactplus-item-footer-bordercolor;

        .listings {
          color: $module-contactplus-item-footer-link-color;

          &:hover {
            color: $module-contactplus-item-footer-link-hover-color;
          }
        }
      }
    }
  }

  .employee-detail,
  .office-detail,
  .branch-detail {
    &__header {
      background-color: $module-contactplus-detail-item-bgcolor;
      box-shadow: $module-contactplus-detail-item-boxshadow;
      margin-bottom: 3rem;

      .image.image-object-fit {
        background-color: #f2f2f2;
        order: 0;

        @include respond-to(lg) {
          width: 30%;
          min-height: 842px;
        }

        img {
          opacity: 1;
          transition: opacity .5s;
          transition-delay: 1.2s;
        }
      }


      .data {
        order: 1;

        .employee_function {
          word-break: break-all;
        }

        .employee__name strong,
        .mainoffice,
        .email,
        .phone {
          color: $brand-color;


        }

        .social {
          border: 1px solid $module-contactplus-detail-item-footer-color;
          color: $module-contactplus-detail-item-footer-color;

          &__facebook {
            border-color: $facebook_color;
            color: $facebook_color;

            &:hover {
              color: #fff;
              background-color: $facebook_color;
              border-color: $facebook_color;
            }
          }

          &__twitter {
            border-color: $twitter_color;
            color: $twitter_color;

            &:hover {
              color: #fff;
              background-color: $twitter_color;
              border-color: $twitter_color;
            }
          }

          &__linkedin {
            border-color: $linkedin_color;
            color: $linkedin_color;

            &:hover {
              color: #fff;
              background-color: $linkedin_color;
              border-color: $linkedin_color;
            }
          }

          &__youtube {
            border-color: $youtube_color;
            color: $youtube_color;

            &:hover {
              color: #fff;
              background-color: $youtube_color;
              border-color: $youtube_color;
            }
          }

          &__googleplus {
            border-color: $google_plus_color;
            color: $google_plus_color;

            &:hover {
              color: #fff;
              background-color: $google_plus_color;
              border-color: $google_plus_color;
            }
          }

          &__instagram {
            border-color: $instagram_color;
            color: $instagram_color;

            &:hover {
              color: #fff;
              background-color: $instagram_color;
              border-color: $instagram_color;
            }
          }

          &__pinterest {
            border-color: $pinterest_color;
            color: $pinterest_color;

            &:hover {
              color: #fff;
              background-color: $pinterest_color;
              border-color: $pinterest_color;
            }
          }
        }
      }
    }

    &__text {
      border-bottom: unset;
      padding: 2rem 0;
      box-shadow: unset;
    }

    &__properties {
      .specials {
        background-color: $module-contactplus-bgcolor;
      }
    }
  }
}

// repositioning img overzicht nieuws
.page26 {
  .image-object-fit {
    & img {
      object-position: top;
    }

    & img[src*="https://hayweb.blob.core.windows.net/public/p6qr1j/1/20210716_Zopfi_054Compressed.jpg"] {
      object-position: 65% top;
    }
  }
}