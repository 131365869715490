.clearcache {
    .cache-confirmation {        
        .icon {            
            border: 1px solid $clearcahce-icon-border;
            background: $clearcahce-icon-bgcolor;            
        }

        .linkiconref {            
            color: $clearcahce-link-color;
        }
    }
}