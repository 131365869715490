.recentlyviewed {
    background-color: $widget-recentlyviewed-bgColor;

    .intro {
        h2 {
            color: $widget-recentlyviewed-color;
        }
    }

    .recentlyviewed-carousel {
        .object {
            background-color: $widget-recentlyviewed-item-bgColor;
            .sys-project-type.full-width {
                background-color: darken($widget-recentlyviewed-item-bgColor, 10%);
            }
        }
    }
}