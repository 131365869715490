#slider1,
#slider2,
#slider3 {
  background: $slider-bgcolor;

  .owl-nav {
    [class*="owl-"] {
      color: $slider-nav-color;
      background: $slider-nav-bgcolor;

      &:hover {
        background: darken($slider-nav-bgcolor, 5%);
        color: $slider-nav-color;
      }
    }
  }

  .owl-dots {
    .owl-dot {
      span {
        border: 2px solid $slider-nav-color;
      }

      &.active span,
      &:hover span {
        background: $slider-nav-color;
        border: 2px solid $slider-nav-color;
      }
    }
  }
}

#outer-slider1 {
  background-color: $slider-bgcolor;
}

#slider1 .slide {
  background-position: center center;
  background-attachment: fixed;
}

#slider1,
#slider2 {
  .slide {
    &:before {
      background: unset;
    }

    background-color: $slider-bgcolor;

    .carouselcaption {
      color: $slider1-caption-color;
      background: $slider1-caption-bgcolor;

      margin: 0 auto;
      text-align: center;

      .sliderTitle {
        color: $slider1-caption-title-color;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $slider1-caption-title-color;
          text-shadow: 10px 10px 15px rgba(#000, 0.5);
        }
      }

      .sliderDesc {
        color: $slider1-caption-title-color;
      }
    }
  }
}

#outer-slider2 {
  background-color: $slider-bgcolor;
}

#slider2 {
  .slide {
    .carouselcaption {
      color: $slider1-caption-color;
      background: $slider1-caption-bgcolor;

      .sliderTitle {
        color: $slider1-caption-title-color;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $slider1-caption-title-color;
          text-shadow: 10px 10px 15px rgba(#000, 0.5);
        }
      }

      .sliderDesc {
        color: $slider1-caption-title-color;
      }
    }
  }

  .owl-nav {
    [class*="owl-"] {
      color: $slider2-nav-color;
      background: $slider2-nav-bgcolor;

      &:hover {
        background: darken($slider2-nav-bgcolor, 5%);
        color: $slider2-nav-color;
      }
    }

    .owl-dots {
      .owl-dot {
        span {
          border: 2px solid $slider2-nav-color;
        }

        &.active span,
        &:hover span {
          background: $slider2-nav-color;
          border: 2px solid $slider2-nav-color;
        }
      }
    }
  }

  #slider3 {
    .slide {
      .carouselcaption {
        background: $slider3-caption-bgcolor;

        .sliderTitle {
          color: #fff;
          color: $slider3-caption-title-color;
        }

        .sliderDesc {
          color: $slider3-caption-title-color;
        }

        .btn {
          color: $slider3-btn-color;
          background: $slider3-btn-bgcolor;

          &:hover {
            color: $slider3-btn-color;
            background: darken($slider3-btn-bgcolor, 5%);
          }

          &.btn-link {
            color: $slider3-btn-color;
            background: transparent;
          }
        }
      }
    }
  }
}

.pageheaderimage {
  background-color: $slider-bgcolor;
}

// nieuwe header image Diensten
.page24,
.page23,
.page34,
.page5,
.page22,
.page35,
.page23,
.page49 {
  .pageheaderimage {
    background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/diensten.jpg);
  }
}

// nieuwe header image Nice to meet you
.page25 {
  .pageheaderimage {
    background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/martin-fijn-ontmoeten.jpg);
    background-position: center;

    @include respond-to(sm) {
      background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/martin-fijn-ontmoeten.jpg);
      background-size: cover;
      background-position: 50% 23%;
    }
  }
}

// nieuwe header image Nieuwbouw
.page36 {
  .pageheaderimage {
    background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/Martin%20foto%20laser.jpg);
    background-position: 66%;

    @include respond-to(sm) {
      background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/Martin%20foto%20laser.jpg);
      background-position: right;
    }
  }
}

// nieuwe header image Comming soon!
.page37 {
  .pageheaderimage {
    background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/comingsoon_1.JPG);
    background-position-x: left;
    background-position-y: top;

    @include respond-to(sm) {
      background-position-x: 44%;
    }

    @include respond-to(lg) {
      background-position-y: 30%;
    }
  }
}

// nieuwe header image funda beoordelingen!
.page39 {
  .pageheaderimage {
    background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/fundaBeoordelingen.jpg);
    background-position-y: bottom;
  }
}

// nieuwe header image plaatselijk woning aanbod!
.page40,
.page41,
.page42,
.page43,
.page44,
.page45,
.page46,
.page47 {
  .pageheaderimage {
    background-image: url(//hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/plaatselijkAanbod.jpg);
  }
}
