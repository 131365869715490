.home {
  .wrapper {
    .mainnavcontainer {
      order: $order-home-header;
    }

    .slider-container {
      order: $order-home-slider;
    }

    .ctaBlocks {
      order: $order-home-ctaBlocks;
    }

    .quicksearch {
      order: $order-home-quicksearch;
    }

    .main-content {
      order: $order-home-mainContent;
    }

    .specials {
      order: $order-home-specials;
    }

    .contentBlocks {
      order: $order-home-contentBlocks;
    }

    .textblock7 {
      order: $order-home-textblock7;
    }

    .textblock8 {
      order: $order-home-textblock8;
    }

    .employees {
      order: $order-home-employees;
    }

    .news {
      order: $order-home-news;
    }

    .recentlyviewed {
      order: $order-home-recentlyviewed;
    }

    .branchelogos {
      order: $order-home-branchelogos;
    }

    .websiteFooter {
      order: $order-home-footer;
    }
  }
}
