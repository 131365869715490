body {
  font-family: $font-family-body;
  color: $font-base-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-header;
}

a {
  color: $link-color;

  &:hover {
    color: darken($link-color, 10%);
  }
}


.wrapper {
  padding-top: calc(#{rem($header-logo-height)} + 2rem);

  @include respond-to(lg) {
    padding-top: calc(#{rem($header-lg-logo-height)} + 2rem)
  }

  .main {
    padding-bottom: 1rem;
  }
}


.check-list {
  li {
    &:before {
      color: $color-green;
    }
  }
}

.fullwidth {
  background-color: $fullwidth-bgcolor;

  &.search #object-search .tab-content .tab-pane {
    background-color: $fullwidth-bgcolor;
  }
}

.cc-link {
  color: $brand-color-secondary !important;
}

.article-title,
h3 {
  color: $brand-color-secondary;
  letter-spacing: .05rem;
  word-break: break-word;
}

.lead {
  font-style: italic;
  color: $brand-color;
}

.home {
  h2 {
    letter-spacing: .15rem;
    color: $brand-color-secondary;
  }

  .textblock7 {

    .lead,
    .article-title {
      color: #fff;
      font-style: normal;
    }
  }
}

@include respond-to(xl) {
  .page35 {
    .sidebar {
      padding: 2rem 15px 2rem 50px;
    }
  }
}
