.news {
  &.widget {
    background-color: $widget-news-bgColor;
    .flex-row {
      .item {
        a {
          background-color: $widget-news-item-bgColor;
          box-shadow: $widget-news-item-boxShadow;
          border-radius: $widget-news-item-borderRadius;
          &:before {
            background-color: $widget-news-item-hover-border;
          }

          .image {
            border-radius: $widget-news-item-borderRadius
              $widget-news-item-borderRadius 0 0;
            .info {
              span {
                background-color: $widget-news-item-info-bgColor;
                color: $widget-news-item-info-color;
                box-shadow: $widget-news-item-info-boxShadow;
                border-radius: $widget-news-item-info-borderRadius;
              }
            }
          }

          .text {
            color: $widget-news-item-text-color;
            h3 {
              word-break: normal;
            }
          }

          .footer {
            border-top: 1px solid $widget-news-item-footer-borderColor;
            .date {
              color: $widget-news-item-footer-color;
            }
          }
        }
      }
    }
  }

  &.newslist {
    .flex {
      .item {
        a {
          border-radius: $module-news-item-borderRadius;
          box-shadow: $module-news-item-boxShadow;
          background-color: $module-news-item-bgColor;
          &:before {
            background-color: $module-news-item-hover-border;
          }

          .image {
            border-radius: $module-news-item-borderRadius
              $module-news-item-borderRadius 0 0;
            .info {
              span {
                background-color: $module-news-item-info-bgColor;
                color: $module-news-item-info-color;
                box-shadow: $module-news-item-info-boxShadow;
                border-radius: $module-news-item-info-borderRadius;
              }
            }
          }

          .text {
            color: $module-news-item-text-color;
            h3 {
              word-break: normal;
            }
          }

          .footer {
            border-top: 1px solid $module-news-item-footer-borderColor;
            .date {
              color: $module-news-item-footer-color;
            }
          }
        }
      }
    }
  }
}

.newspage {
  .main {
    background-color: $module-news-bgColor;
    padding: 3rem 0;
  }
}

.newsdetailpage {
  background-color: $module-news-bgColor;

  .wrapper {
    padding-top: 0;
  }

  .news.newsitem {
    .pageheader {
      background: url(https://hayweb.blob.core.windows.net/public/p6qr1j/2-beta/slide/diensten.jpg);
      background-size: cover !important;
      background-position: center;
      height: 400px;
      background-color: $slider-bgcolor;

      .content {
        opacity: 0;
      }
    }

    .newscontent {
      .imgArticle {
        min-height: 100px;
        max-height: 250px;
        margin: 0 0 2rem 0;
        overflow: hidden;
        display: flex;
        align-items: center;

        img {
          width: 100%;
        }
      }
      background-color: $widget-news-item-bgColor;
    }

    .footer {
      border-top: 1px solid $module-news-item-footer-borderColor;
      box-shadow: $module-news-item-boxShadow;
      background-color: $module-news-item-footer-bgColor;
      .share_options_website {
        .btn {
          border-radius: $border-radius-small !important;
          &.addthis_button_facebook {
            background: $facebook_color;
            color: #fff;

            &:hover {
              background: darken($facebook_color, 10%);
            }
          }

          &.addthis_button_whatsapp {
            background: $whatsapp_color;
            color: #fff;

            &:hover {
              background: darken($whatsapp_color, 10%);
            }
          }

          &.addthis_button_twitter {
            background: $twitter_color;
            color: #fff;

            &:hover {
              background: darken($twitter_color, 10%);
            }
          }
        }
      }
    }
  }
}
