$brand-color: #ed6b06;
$brand-color-secondary: #352874;
$brand-color-gray: #9d9d9c;

$gray-darker: lighten(#000, 13.5%) !default;
$gray-dark: lighten(#000, 20%) !default;
$gray: lighten(#000, 33.5%) !default;
$gray-light: lighten(#000, 60%) !default;
$gray-lighter: lighten(#000, 93.5%) !default;

/* COLORS */
$color-gray: #808080 !default;
$color-white: #ffffff !default;
$color-blue: #007bff !default;
$color-indigo: #6610f2 !default;
$color-purple: #6f42c1 !default;
$color-pink: $brand-color !default;
$color-red: #dc3545 !default;
$color-orange: #e9911d !default;
$color-yellow: #ffc107 !default;
$color-green: $brand-color !default;
$color-teal: #20c997 !default;
$color-cyan: #17a2b8 !default;

$link-color: $brand-color-secondary;
$link-hover-color: darken($brand-color-secondary, 10%);

/* BRAND COLORS */
$brand-primary: $brand-color;
$brand-success: $color-green !default;
$brand-info: $color-cyan !default;
$brand-warning: $color-orange !default;
$brand-danger: $color-red !default;

$border-radius-default: 0;
$border-radius-small: 0;

/* GLOBAL */
$font-base-color: #444444;
$font-base-size: 16px;
$font-family-header: "Montserrat", sans-serif;
$font-family-body: "Montserrat", sans-serif;
$font-family-written: "Indie Flower", cursive;

$default-boxShadow: 0 2px 0 0 #edeae8;
$fullwidth-bgcolor: #f9f7f5;
$formControlActive: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

/* SOCIAL MEDIA */
$facebook_color: #365397;
$twitter_color: #00a9f1;
$linkedin_color: #006db3;
$google_plus_color: #e0452c;
$youtube_color: #ff3333;
$pinterest_color: #ce1a19;
$instagram_color: #c13584;
$whatsapp_color: #25d366;

$funda-color: #925b00;

/* BLOCKS POSITIONS - HOME (only for homepage, include partial below) */
$order-home-header: 0 !default;
$order-home-slider: 1 !default;
$order-home-ctaBlocks: 2 !default;
$order-home-quicksearch: 3 !default;
$order-home-mainContent: 5 !default;
$order-home-specials: 6 !default;
$order-home-contentBlocks: 7 !default;
$order-home-textblock7: 8 !default;
$order-home-textblock8: 9 !default;
$order-home-employees: 10 !default;
$order-home-news: 11 !default;
$order-home-recentlyviewed: 12 !default;
$order-home-branchelogos: 13 !default;
$order-home-footer: 99 !default;

/* BUTTONS */
$btn-font-weight: 300 !default;

$btn-default-color: #444 !default;
$btn-default-bg: #d6d6d6;
$btn-default-border: $btn-default-bg !default;

$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: $btn-primary-bg !default;

$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: $btn-success-bg !default;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: $btn-info-bg !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: $btn-warning-bg !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: $btn-danger-bg !default;

$btn-link-disabled-color: $gray-light !default;

$padding-base-vertical: 0.5rem !default;
$padding-base-horizontal: 1rem !default;

$padding-large-vertical: 0.6rem !default;
$padding-large-horizontal: 1.2rem !default;

$padding-small-vertical: 0.4rem !default;
$padding-small-horizontal: 1rem !default;

$padding-xs-vertical: 0.2rem !default;
$padding-xs-horizontal: 0.5rem !default;

$line-height-large: 1.5 !default;
$line-height-small: 1.5 !default;

$border-radius-base: 0 !default;
$border-radius-large: 0 !default;
$border-radius-small: 0 !default;

/* HEADER */
$header-order-mainnavbar: 2;
$header-order-accountwidget: 3;
$header-order-langpicker: 4;
$header-order-phonenumber: 5;
$header-order-whatsapp: 6;
$header-order-navbartoggle: 99;

$header-logo-height: 48px;
$header-logo-width: 134px;
$header-lg-logo-height: 58px;
$header-lg-logo-width: 160px;

$header-bgcolor: #fff;
$header-boxshadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
$header-bordercolor: #edeae8;
$header-navbartoggle-bgcolor: $brand-color;
$header-navbartoggle-color: #fff;

$header-nav-collapse-bgcolor: #fff;
$header-nav-collapse-bordercolor: #edeae8;
$header-nav-collapse-link-color: $font-base-color;
$header-nav-collapse-submenu-link-color: lighten($font-base-color, 5%);

$header-nav-bordercolor: $header-bordercolor;

$header-nav-link-bordercolor: $header-bordercolor;
$header-nav-link-color: $font-base-color;
$header-nav-link-hover-color: $brand-color;

$header-nav-dropdown-bgcolor: #f2f2f2;
$header-nav-dropdown-boxshadow: $header-boxshadow;
$header-nav-dropdown-item-link-bordercolor: rgba(#000, 0.1);
$header-nav-dropdown-item-link-color: $font-base-color;
$header-nav-dropdown-item-link-hover-color: darken($brand-color, 5%);
$header-nav-dropdown-active-item-bgcolor: #f2f2f2;
$header-nav-dropdown-active-item-link-color: $brand-color;
$header-nav-dropdown-active-item-link-bgcolor: $header-nav-dropdown-active-item-bgcolor;

$header-nav-dropdown-submenu-bgcolor: #f2f2f2;
$header-nav-dropdown-submenu-boxshadow: $header-boxshadow;

/* HEADER - PHONENUMER */
$header-phonenumber-color: #fff;
$header-phonenumber-bgcolor: $brand-color;

/* HEADER - ACCOUNT */
$header-accountwidget-bordercolor: $header-bordercolor;
$header-accountwidget-link-color: $header-nav-link-color;
$header-accountwidget-link-hover-color: $header-nav-link-hover-color;
$header-accountwidget-dropdown-bordercolor: #ccc;
$header-accountwidget-dropdown-bgcolor: $header-nav-dropdown-bgcolor;

/* HEADER - LANGPICKER */
$header-langpicker-bordercolor: #edeae8;
$header-langpicker-color: $font-base-color;
$header-langpicker-hover-color: $brand-color;
$header-langpicker-dropdown-bordercolor: $header-bordercolor;
$header-langpicker-dropdown-bgcolor: $header-nav-dropdown-bgcolor;
$header-langpicker-dropdown-item-color: $font-base-color;
$header-langpicker-dropdown-item-hover-color: $header-nav-link-hover-color;

// FOOTER - LIGHT
$footer-bgcolor: #f2f2f2;
$footer-color: #444;
$footer-title-color: #444;
$footer-icon-color: $brand-color;
$footer-menu-color: $footer-color;
$footer-menu-color-hover: $brand-color;
$footer-sub-border-color: #c7c7c7;
$footer-sub-copyright-color: $footer-color;
$footer-sub-menu-color: $footer-color;
$footer-sub-menu-color-hover: $brand-color;

/* FOOTER - DARK */
// $footer-bgcolor: #111;
// $footer-color: #b7b7b7;
// $footer-title-color: #fff;
// $footer-icon-color: $brand-color;
// $footer-menu-color: $footer-color;
// $footer-menu-color-hover: #fff;
// $footer-sub-border-color: rgba(255, 255, 255, 0.1);
// $footer-sub-copyright-color: $footer-color;
// $footer-sub-menu-color: $footer-color;
// $footer-sub-menu-color-hover: #fff;

/* SLIDER */
$slider-bgcolor: #f4f4f4;
$slider-nav-color: #444;
$slider-nav-bgcolor: transparent;

$slider1-caption-bgcolor: transparent;
$slider1-caption-title-color: #fff;
$slider1-caption-color: #444;
$slider1-btn-color: #fff;
$slider1-btn-bgcolor: #444;

$slider2-caption-bgcolor: transparent;
$slider2-caption-title-color: #fff;
$slider2-caption-color: #fff;
$slider2-btn-color: $slider1-btn-color;
$slider2-btn-bgcolor: $slider1-btn-bgcolor;

$slider2-nav-color: #fff;
$slider2-nav-bgcolor: transparent;

$slider3-caption-bgcolor: $slider1-caption-bgcolor;
$slider3-caption-title-color: $slider1-caption-title-color;
$slider3-btn-color: $slider1-btn-color;
$slider3-btn-bgcolor: $slider1-btn-bgcolor;

/* CONTACTBLOCKS */
$ctaBlocks-bgcolor: #f2f2f2;
$ctaBlocks-block-bgcolor: #fff;
$ctaBlocks-block-boxshadow: $default-boxShadow;
$contentBlocks-bgcolor: #fff;

/* GRIDCARDS */
$gridCards-bgcolor: #f2f2f2;
$gridCards-block-bgcolor: #fff;
$gridCards-block-boxshadow: $default-boxShadow;

/* API */
$clearcahce-icon-border: #bbb;
$clearcahce-icon-bgcolor: #fbfbfb;
$clearcahce-link-color: #999;

/* BREADCRUMB */
$widget-breadcrumb-bgcolor: #fff;
$widget-breadcrumb-item-color: $font-base-color;
$widget-breadcrumb-item-active-color: $brand-color;

/* CONTACT */
$module-contact-bgColor: $fullwidth-bgcolor;
$module-contact-pageheader-color: #333;
$module-contact-mapmarker-color: $brand-color;
$module-contact-block-boxShadow: $default-boxShadow;
$module-contact-block-text-bgColor: #fff;
$module-contact-block-color: #fff;
$module-contact-block-bgColor: $brand-color;
$module-contact-block-bgGradient: linear-gradient(
  180deg,
  rgba($module-contact-block-bgColor, 1) 0%,
  rgba(darken($module-contact-block-bgColor, 20%), 1) 100%
);

/* EMPLOYEES*/
$widget-employees-bgcolor: #fff;
$widget-employees-slider-nav-color: $slider-nav-color;
$widget-employees-slider-nav-bgcolor: $slider-nav-bgcolor;
$widget-employees-item-color: #fff;
$widget-employees-item-hover-bgcolor: $brand-color;
$widget-employees-item-nophoto-color: $widget-employees-item-color;
$widget-employees-item-nophoto-bgcolor: #444;

$module-contactplus-bgcolor: $fullwidth-bgcolor;
$module-contactplus-item-bgcolor: #fff;
$module-contactplus-item-boxshadow: $default-boxShadow;
$module-contactplus-item-hover-border: $brand-color;
$module-contactplus-item-data-color: $font-base-color;
$module-contactplus-item-footer-bordercolor: #f4f4f4;
$module-contactplus-item-footer-link-color: $link-color;
$module-contactplus-item-footer-link-hover-color: $link-hover-color;
$module-contactplus-detail-item-bgcolor: #fff;
$module-contactplus-detail-item-boxshadow: $module-contactplus-item-boxshadow;
$module-contactplus-detail-item-footer-color: $footer-color;
$module-contactplus-detail-item-text-bgcolor: #fff;
$module-contactplus-detail-item-text-boxshadow: $module-contactplus-item-boxshadow;

/* TESTIMONIALS */
$module-testimonials-number-color: $brand-color;
$module-testimonials-item-bgcolor: #f2f2f1;
$module-testimonials-item-rating-color: $brand-color;
$module-testimonials-item-rating-bgcolor: #fff;
$module-testimonials-item-author-color: $brand-color;

/* NEWS */
$module-news-bgColor: #f9f7f5;
$module-news-item-bgColor: #fff;
$module-news-item-hover-border: $brand-color;
$module-news-item-boxShadow: $default-boxShadow;
$module-news-item-borderRadius: $border-radius-large;
$module-news-item-info-borderRadius: $border-radius-small;
$module-news-item-info-boxShadow: 0 1px 6px rgba(61, 65, 84, 0.15);
$module-news-item-info-bgColor: #fff;
$module-news-item-info-color: #444;
$module-news-item-text-color: $font-base-color;
$module-news-item-footer-borderColor: #f4f4f4;
$module-news-item-footer-bgColor: #fff;
$module-news-item-footer-color: #6d6d6d;

$widget-news-bgColor: $module-news-bgColor;
$widget-news-item-bgColor: $module-news-item-bgColor;
$widget-news-item-hover-border: $module-news-item-hover-border;
$widget-news-item-boxShadow: $module-news-item-boxShadow;
$widget-news-item-borderRadius: $module-news-item-borderRadius;
$widget-news-item-info-borderRadius: $module-news-item-info-borderRadius;
$widget-news-item-info-boxShadow: $module-news-item-info-boxShadow;
$widget-news-item-info-bgColor: $module-news-item-info-bgColor;
$widget-news-item-info-color: $module-news-item-info-color;
$widget-news-item-text-color: $font-base-color;
$widget-news-item-footer-borderColor: $module-news-item-footer-borderColor;
$widget-news-item-footer-color: $module-news-item-footer-color;

/* PROMO */
$widget-alert-promo-color: $color-white;
$widget-alert-promo-bgcolor: $brand-color;

/* QUICKSEARCH */
$widget-quicksearch-bgColor: #f2f2f2;
$widget-quicksearch-borderColor: #ced4da;
$widget-quicksearch-tab-color: $font-base-color;
$widget-quicksearch-tab-bgColor: $widget-quicksearch-bgColor;
$widget-quicksearch-formControlActive: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
$widget-quicksearch-input-bgColor: $color-white;
$widget-quicksearch-input-borderColor: $widget-quicksearch-borderColor;
$widget-quicksearch-searchbtn-color: $color-white;
$widget-quicksearch-searchbtn-bgColor: $brand-primary;
$widget-quicksearch-select2-input-color: $font-base-color;
$widget-quicksearch-select2-input-bgColor: $widget-quicksearch-input-bgColor;
$widget-quicksearch-select2-item-hover-bgColor: $brand-color;
$widget-quicksearch-select2-item-hover-color: $color-white;
$widget-quicksearch-btn-font-weight: 400;
$widget-quicksearch-dropdown-bgColor: #fff;
$widget-quicksearch-dropdown-color: #fff;
$widget-quicksearch-dropdown-borderColor: rgba(0, 0, 0, 0.15);
$widget-quicksearch-dropdown-item-color: #212529;
$widget-quicksearch-dropdown-item-bgColor: transparent;
$widget-quicksearch-dropdown-item-hover-color: #16181b;
$widget-quicksearch-dropdown-item-hover-bgColor: #f8f9fa;
$widget-quicksearch-dropdown-item-selected-color: #fff;
$widget-quicksearch-dropdown-item-selected-bgColor: $brand-color;

/* SPECIALS */
$specials-widget-bg: #f2f2f2;
$specials-widget-object-hover-border: $brand-color;
$specials-widget-object-bg: #fff;
$specials-widget-object-color: #444;

$specials-widget-feature-bg: $specials-widget-object-bg;
$specials-widget-feature-border-color: #f4f4f4;
$specials-widget-feature-icon-color: #444;
$specials-widget-feature-icon-bg: $specials-widget-feature-border-color;

$specials-widget-status-normal-bg: rgba(#ffffff, 0.9);
$specials-widget-status-normal-color: $brand-color;
$specials-widget-status-sold-rented-bg: rgba(#fff, 0.9);
$specials-widget-status-sold-rented-color: $brand-color-secondary;
$specials-widget-status-under-conditions-bg: rgba(#fff, 0.9);
$specials-widget-status-under-conditions-color: $brand-color-secondary;
$specials-widget-status-new-bg: rgba(#fff, 0.9);
$specials-widget-status-new-color: $brand-color;
$specials-widget-status-pricechanged-bg: rgba(#3498db, 0.9);
$specials-widget-status-pricechanged-color: #ffffff;
$specials-widget-status-openhouse-bg: rgba($brand-color, 0.9);
$specials-widget-status-openhouse-color: $color-white;

$specials-widget-status-topper-bg: #e9911d;
$specials-widget-status-topper-color: #ffffff;

/* RECENTLY VIEWED */
$widget-recentlyviewed-color: $font-base-color;
$widget-recentlyviewed-bgColor: rgba($brand-primary, 0.04);
$widget-recentlyviewed-item-bgColor: #fff;

/* TESTIMONIALS */
$module-testimonials-item-bgcolor: #f2f2f1;
$module-testimonials-item-title-color: $brand-color;
$module-testimonials-item-location-color: $font-base-color;
$module-testimonials-item-contact-name-font-family: $font-family-written;

/* BRANCHELOGOS */
$widget-branchelogos-bgcolor: #fff;
$widget-branchelogos-nav-color: $slider-nav-color;
$widget-branchelogos-nav-bgColor: $slider-nav-color;
$widget-branchelogos-nav-hover-color: $slider-nav-color;
$widget-branchelogos-nav-hover-bgColor: darken($slider-nav-bgcolor, 5%);
$widget-branchelogos-nav-dotColor: $slider-nav-color;

/* GLOBAL*/
@import "global/mixins";
@import "global/transitions";
@import "global/functions";
@import "global/buttons";
@import "global/forms";
@import "global/global";

/* LAYOUT*/
@import "layout/order";
/* uncomment to define order of home page */
@import "layout/home";
@import "layout/mainnav";
@import "layout/slider";
@import "layout/quicksearch";
@import "layout/breadcrumb";
@import "layout/specials";
@import "layout/employees";
@import "layout/news";
@import "layout/widget_branchlogos";
@import "layout/widget_social";
@import "layout/widget_recentlyviewed";

//@import 'layout/cookieconsent';
@import "layout/testimonials";
@import "layout/textpage";
@import "layout/contact";
@import "layout/insocial";
@import "layout/footer";
@import "layout/widget_whatsapp";
@import "layout/api";

//@import 'layout/faq';
//@import 'layout/links';
//@import 'layout/member';
@import "layout/promo";
