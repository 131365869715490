.customer-testimonials {
    .main-article {
        .number {            
            color: $module-testimonials-number-color;
        }
    }

    .module-testimonials-content {

        .testimonial-container {
            .testimonial {                
                background-color: $module-testimonials-item-bgcolor;

                .avatarcontainer {
                    .rating {
                        background-color: $module-testimonials-item-rating-bgcolor;
                        color: $module-testimonials-item-rating-color;
                    }
                }

                .author {
                    color: $module-testimonials-item-author-color;
                }
            }
        }
    }
}