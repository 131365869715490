@mixin respond-to($breakpoint) {
    /* max 576px */
    @if $breakpoint == 'sm-max' {
        @media (max-width: 576px) {
            @content;
        }
    }
    /* max 576px */
    @if $breakpoint == 'md-max' {
        @media (max-width: 768px) {
            @content;
        }
    }
    /* max 576px */
    @if $breakpoint == 'lg-max' {
        @media (max-width: 992px) {
            @content;
        }
    }
    /* 576px */
    @if $breakpoint == 'sm' {
        @media (min-width: 576px) {
            @content;
        }
    }
    @else if $breakpoint == 'md' {
        @media (min-width: 768px) {
            @content;
        }
    }
    @else if $breakpoint == 'lg' {
        @media (min-width: 992px) {
            @content;
        }
    }
    @else if $breakpoint == 'xl' {
        @media (min-width: 1200px) {
            @content;
        }
    }
    @else if $breakpoint == 'xxl' {
        @media (min-width: 1450px) {
            @content;
        }
    }
    @else if $breakpoint == 'xxxl' {
        @media (min-width: 1650px) {
            @content;
        }
    }
}

/* https://css-tricks.com/snippets/sass/centering-mixin/*/
@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/*/
@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: $height / $width * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin feature-button($bgcolor, $textcolor) {
  position: relative;
  border-radius: 5px;
  display: inline-block;
  font-weight: bold;
  border: 2px solid $bgcolor;
  padding: 8px 16px;
  margin-bottom: 2rem;
  transition: all 0.2s ease-in-out;
  z-index: 0;
  text-decoration: none;

  &:before {
    content: '';
    display: block;
    width: 0;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: $bgcolor;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    color: $color-white;

    &:before {
      width: 100%;
    }
  }
}

@mixin textblock-variant($variant, $bgcolor, $color) {
  @if $variant == 'fullWidthBackground' {
    display: flex;
    position: relative;
    align-items: center;
    background-color: $bgcolor;
    padding: 60px 0 calc(60px - 1rem)!important;

    @include respond-to(xl) {
      padding: 100px 0 calc(100px - 1rem)!important;
    }


    .text {
      position: relative;
      z-index: 2;
      color: $color;
    }

    .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .5;
      z-index: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @if $variant == 'imageRight' {
    background-color: $bgcolor;
    padding: 60px 0;

    @include respond-to(xl) {
      padding: 100px 0;
    }


    > .container {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 0;

      @include respond-to(md) {
        flex-flow: row nowrap;
      }


      .text {
        color: $color;
      }

      > div {
        flex: 100% 0 1;
        padding: 0 15px;
      }
    }
  }

  @if $variant == 'imageLeft' {
    background-color: $bgcolor;
    padding: 60px 0;

    @include respond-to(xl) {
      padding: 100px 0;
    }


    > .container {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 0;

      @include respond-to(md) {
        flex-flow: row nowrap;
      }


      .text {
        color: $color;
      }

      > div {
        flex: 100% 0 1;
        padding: 0 15px;
      }

      .text {
        order: 2;
      }

      .image {
        order: 1;
      }
    }
  }

  @if $variant == 'imageTop' {
    background-color: $bgcolor;
    padding: 60px 0;

    @include respond-to(xl) {
      padding: 100px 0;
    }


    > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-align: center;

      > div {
        flex: 100% 0 1;
        padding: 0 15px;
      }

      .text {
        order: 2;
        color: $color;
      }

      .image {
        order: 1;
        margin-bottom: 1em;
      }
    }
  }

  @if $variant == 'imageBottom' {
    background-color: $bgcolor;
    padding: 60px 0;

    @include respond-to(xl) {
      padding: 100px 0;
    }


    > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-align: center;

      > div {
        flex: 100% 0 1;
        padding: 0 15px;
      }

      .text {
        order: 1;
        color: $color;
      }

      .image {
        order: 2;
        margin-top: 1em;
      }
    }
  }

  @if $variant == 'blockImageRight' {
    background-color: $bgcolor;    
    padding: 60px 15px;

    @include respond-to(xl) {
      padding: 100px 15px;
    }


    > .container {
      max-width: 1190px;
      background-color: #fff;
      box-shadow: 0 2px 0 0 #edeae8;
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      padding: 0;

      @include respond-to(md) {
        flex-flow: row nowrap;
      }
    }
      .text {
        order: 1;        
        padding: 20px;
        color: $color;
        
        @include respond-to(md) {
          order: 0;
          padding: 60px;
        }
      }

      .image {
        flex-shrink: 0;
        order: 0;
        width: 100%;
        
        @include aspect-ratio(4, 4);

        > .image {
          position: absolute;        
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;                                    
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        @include respond-to(md) {
          width: 50%;
          order: 1;
        }
      }    
  }
}