.mainnav.navbar {

  opacity: 1;
  box-shadow: $header-boxshadow;
  background-color: $header-bgcolor;

  .navbar-brand {
    height: calc(#{rem($header-logo-height)} + 2rem);
    width: calc(#{rem($header-logo-width)} + 1rem);
    position: relative;


    @include respond-to(lg) {
      border-right: transparent;
      height: calc(#{rem($header-lg-logo-height)} + 2rem);
      width: calc(#{rem($header-lg-logo-width)} + 2rem);
      z-index: 998;
    }

    img {
      opacity: 1;
      height: 100%;
      width: 100%;

      border-bottom: 1px solid transparent;
      background: $header-bgcolor;
      left: 0;
      top: 0;

      @include respond-to(sm) {
        border-right: 1px solid $header-bordercolor;

      }
    }

    &.affix {
      padding: 10px;

      img {
        padding: 10px;
        height: calc(#{rem($header-logo-height)} + 1rem);

        @include respond-to(lg) {
          height: calc(#{rem($header-lg-logo-height)} + 1rem);
        }
      }

      &:after {
        height: calc(#{rem($header-logo-height)} + 1rem);
        opacity: 0;

        @include respond-to(lg) {
          height: calc(#{rem($header-lg-logo-height)} + 1rem);
        }
      }
    }
  }

  .navbar-toggler {
    order: $header-order-navbartoggle;
    background-color: $header-navbartoggle-bgcolor;
    border-color: $header-navbartoggle-bgcolor;

    .icon {
      color: $header-navbartoggle-color;

      span {
        background: $header-navbartoggle-color;
      }
    }
  }

  .mainnavbar {
    order: $header-order-mainnavbar;
    letter-spacing: 0.05rem;

    @include respond-to(lg-max) {
      &.navbar-collapse {
        top: calc(#{rem($header-logo-height)} + 2rem);
        border-top: 1px solid $header-nav-bordercolor;
        background-color: $header-nav-collapse-bgcolor;
        height: calc(100vh - #{$header-lg-logo-height}) !important;
      }
    }

    .navbar-nav,
    .navbar-nav.menu-main {
      width: 100%;
      height: auto;
      font-size: 14px;

      >.nav-item {
        padding: 0 10px;

        &:nth-last-of-type(2) {
          margin-left: auto;

          >a {
            background-image: url('//hayweb.blob.core.windows.net/public/00_branchelogos/eerlijkbieden/eerlijkbieden.svg');
            color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 10rem;

            &:hover {
              color: transparent;
            }
          }
        }

        &:last-of-type {

          >a {
            background-image: url('//hayweb.blob.core.windows.net/public/00_branchelogos/VastgoedNed/vastgoedned.svg');
            color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 5rem;

            &:hover {
              color: transparent;
            }
          }
        }

        >.nav-link {
          border-bottom: 1px solid $header-nav-link-bordercolor;
          color: $brand-color-secondary;

          @include respond-to(lg) {
            border-bottom: 0;
          }

          @include respond-to(lg-max) {
            border-bottom: 1px solid $header-nav-collapse-bordercolor;
            color: $header-nav-collapse-link-color;
          }
        }

        >.dropdown-menu {
          @include respond-to(lg) {
            box-shadow: $header-nav-dropdown-boxshadow;
            top: calc(#{rem($header-lg-logo-height)} + 2rem);
            background-color: $header-nav-dropdown-bgcolor;
            justify-content: center;
          }

          >.dropdown-item {
            >.nav-link {
              // border-bottom: 1px solid $header-nav-dropdown-item-link-bordercolor;
              // color: $header-nav-dropdown-item-link-color;

              &:hover {
                color: $header-nav-dropdown-item-link-hover-color;
              }
            }

            &:last-child {
              >.nav-link {
                border-bottom: 0;
              }
            }

            &.active {
              @include respond-to(xl) {
                background-color: $header-nav-dropdown-active-item-bgcolor;
              }

              >.nav-link {
                color: $header-nav-dropdown-active-item-link-color;

                @include respond-to(xl) {
                  background-color: $header-nav-dropdown-active-item-link-bgcolor;
                }
              }
            }
          }

          .dropdown-submenu {
            .dropdown-menu {
              box-shadow: $header-nav-dropdown-submenu-boxshadow;
              background-color: $header-nav-dropdown-submenu-bgcolor;
            }
          }
        }

        &:hover {
          >.nav-link {
            color: $header-nav-link-hover-color;
          }
        }

        &.active {
          >.nav-link {
            color: $header-nav-link-hover-color;
          }
        }
      }
    }
  }

  .widget.phonenumber {
    order: $header-order-phonenumber;
    background-color: $header-phonenumber-bgcolor;
    color: $header-phonenumber-color;

    &:hover,
    &.open {
      background-color: darken($header-phonenumber-bgcolor, 5%);
    }
  }

  #account-widget {
    order: $header-order-accountwidget;
    border-left: 1px solid $header-accountwidget-bordercolor;

    .account-container {
      .nav-item {
        .avatar {
          border: 1px solid $header-accountwidget-bordercolor;
        }

        .nav-link {
          color: $header-accountwidget-link-color;

          &:hover {
            color: $header-accountwidget-link-hover-color;
          }
        }
      }

      .dropdown-menu {
        border: 1px solid $header-accountwidget-dropdown-bordercolor;
        background-color: $header-accountwidget-dropdown-bgcolor;
      }
    }
  }

  .widget.lang-picker {
    order: $header-order-langpicker;
    border-left: 1px solid $header-langpicker-bordercolor;

    .dropdown {
      >.lang-active {
        color: $header-langpicker-color;

        &:hover {
          text-decoration: none;
          color: $header-langpicker-hover-color;
        }
      }

      >.dropdown-menu {
        border: 1px solid $header-langpicker-dropdown-bordercolor;
        background-color: $header-langpicker-dropdown-bgcolor;

        >.dropdown-item {
          color: $header-langpicker-dropdown-item-color;

          .nav-link {
            color: $header-langpicker-dropdown-item-color;
          }

          &.active {
            color: $header-langpicker-dropdown-item-hover-color;
          }

          &:hover {
            .nav-link {
              color: $header-langpicker-dropdown-item-hover-color;
            }
          }
        }
      }
    }
  }
}