

.widget.branchelogos {
    background: $widget-branchelogos-bgcolor;
    
    .owl-nav {
        display: none;
        [class*='owl-'] {
            color: $widget-branchelogos-nav-color;
            background: $widget-branchelogos-nav-bgColor;
            &:hover {
                background: $widget-branchelogos-nav-hover-bgColor;
                color: $widget-branchelogos-nav-hover-color;
            }
        }    
    }

    .owl-dots {
        .owl-dot {
            span {
                border: 2px solid $widget-branchelogos-nav-dotColor;
            }

            &.active span,
            &:hover span {
                background: $widget-branchelogos-nav-dotColor;
                border: 2px solid $widget-branchelogos-nav-dotColor;
            }
        }
    }
}