.breadcrumb-container {
    .breadcrumb {        
        .breadcrumb-item {
            a {
                color: $widget-breadcrumb-item-color;
            }

            &.active {
                a {
                    color: $widget-breadcrumb-item-active-color;
                }
            }
        }

        .breadcrumb-item + .breadcrumb-item::before {
            color: $widget-breadcrumb-item-color;            
        }
    }
}

.fullwidth {
    .breadcrumbs {
        background-color: $widget-breadcrumb-bgcolor;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.07);
    }
}