.page {
  .textblock7 {
    @include textblock-variant(blockImageRight, #f9f7f5, $font-base-color);
  }

  .textblock8 {
    @include textblock-variant(fullWidthBackground, shade($brand-color, 60%), #fff);
    text-align: center;
  }

  &24{
    .col-lg.main{
      padding-bottom: 0;
    }
  }
  &39 {
    iframe {
      max-width: 975px;
      margin-left: -15px;
    }
  }
}

.grid-cards {
  .card {
    box-shadow: $ctaBlocks-block-boxshadow;
    background-color: $ctaBlocks-block-bgcolor;
    &__content {
      .btn {
        margin-top: 1rem;
        border: 1px solid #fff;
        color: #fff;

        &:hover {
          background-color: #fff;
          color: $brand-color;
        }
      }
    }

    &__funda {
      background-color: $funda-color;
      color: #fff;
      box-shadow: 0 2px 0 0 darken($funda-color, 8%);

      img {
        max-width: 80px;
      }
      .btn {
        &:hover {
          background-color: #fff;
          color: $funda-color;
        }
      }
    }

    &-primary {
      box-shadow: 0 2px 0 0 darken($brand-primary, 8%);
      background-color: $brand-primary;
      color: #fff;
    }

    &-success {
      box-shadow: 0 2px 0 0 darken($brand-success, 8%);
      background-color: $brand-success;
      color: #fff;
    }

    &-info {
      box-shadow: 0 2px 0 0 darken($brand-info, 8%);
      background-color: $brand-info;
      color: #fff;
    }

    &-warning {
      box-shadow: 0 2px 0 0 darken($brand-warning, 8%);
      background-color: $brand-warning;
      color: #fff;
    }

    &-danger {
      box-shadow: 0 2px 0 0 darken($brand-danger, 8%);
      background-color: $brand-danger;
      color: #fff;
    }
  }
}

// COMING SOON BLOK INTERPETATIE
.objectgrid {
  margin: 3rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;

  @include respond-to(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond-to(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .object {
    overflow: hidden;
    display: block;
    border: 1px solid #ccc;
    @include aspect-ratio(4, 4);

    >img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 500ms ease-in-out;
    }

    .status {
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12.8px;
      background-color: rgba(255, 255, 255, .9);
      color: $brand-color;
      padding: 8px 16px;
      pointer-events: none;
      font-weight: bold;
      z-index: 5;
      text-align: center;
    }

    >.content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
      padding: 15px;
      overflow: hidden;

      .street {
        font-size: 1em;
        display: block;
        font-weight: bold;
        color: #fff;
        margin: 0;
      }

      .city {
        font-size: 1em;
        display: block;
        color: #fff;
        text-shadow: 0 0 10px #000;
        margin-bottom: .4em;

        &.last {
          text-shadow: none;
        }
      }
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
}

//TIJDELIJK
.home {
  .main-content {
    display: none;
  }
  .text-block7 {
   .container {
     padding: 2rem;
   } 
  }
}



// Custom stijling labels
.berlin {
  .object {
    .object_status {
      background-color: rgba(255, 255, 255, .9)!important;
      color: $brand-color!important;
      &.sold, 
      &.sold_under_conditions{
        color: $brand-color-secondary!important;
      }
    }
  }
}

// Account inlo stijling
.page11 {
  .heading p {
    display: none;
  }
}

// Verkopen arrow styling
.c2a_video {
  @include respond-to(sm){
    margin-top: unset;
  }

  display: flex;
  justify-content: space-between;

  .arrowImg {
    pointer-events: none;
    position: relative;
    top: -100px;
    width: 90px;
  }
}