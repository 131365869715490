.ctaBlocks {
    background-color: $ctaBlocks-bgcolor;

    .ctaBlock {
        background-color: $ctaBlocks-block-bgcolor;
        box-shadow: $ctaBlocks-block-boxshadow;

        .text {
            height: 100%;
            display: flex;
            flex-direction: column;
            p:first-of-type {
                flex-grow: 1;
            }
        }
    }
}

.contentBlocks {
    background-color: $contentBlocks-bgcolor;
}

.home {
    .main-content {
        @include textblock-variant(imageRight, #fff, $font-base-color);
    }
    .contentBlocks {
        background-color: shade($brand-color, 75%);
        color: #fff;
        /*
        .textblock4, .textblock5, .textblock6 {
            text-align: center;
        } 
        */
    }
    // fullbackground image fullWidthBackground
    .textblock7 {
        @include textblock-variant(fullWidthBackground, shade($brand-color, 50%), $font-base-color);

        // Modification for showing tabs.
        padding: 0;
        @include respond-to(xl) {
            padding: 60px 0 calc(60px - 1rem);
        }
        .text {
            text-align: center;
            color: #fff;
            max-width: 800px;
            margin: 0 auto;
            //background-color: $contentBlocks-bgcolor;
            //box-shadow: 0 2px 0 0 darken($contentBlocks-bgcolor, 8%);
            margin-bottom: 15px;

            @include respond-to(lg) {
                margin-bottom: 25px;
            }
            .nav-pills {
                .nav-item {
                    .nav-link {                        
                        padding: 1rem;                        
                        background-color: #f9f7f5;
                        border-top: 3px solid #f9f7f5;
                        border-bottom: 3px solid #f9f7f5;
                        &.active {
                            background-color: $contentBlocks-bgcolor;
                            color: $font-base-color;
                            border-top: 3px solid $brand-color;
                            border-bottom: 3px solid $contentBlocks-bgcolor;
                        }
                    }
                }
            }
            .tab-content {
                .tab-pane {
                    padding: 2rem;
                    min-height: 360px;
                    @include respond-to(md) {
                        padding: 2rem 3rem;
                    }
                    p:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    // fullWidthBackground, imageRight, imageLeft, imageTop, imageBottom
    // for centered text use imageTop and dont use an image in the article
    .textblock8 {
        @include textblock-variant(imageTop, #f9f7f5, $font-base-color);
    }
}

.sys-textblock {
    .text {
        .nav {
            &-pills {
                .nav-item {
                    .nav-link {
                        border-radius: $border-radius-default;
                    }
                }
            }
        }
    }
}
