.module-testimonials {
  .module-testimonials-content {
    .testimonial {
      background-color: $module-testimonials-item-bgcolor;

      .testimonial-header {
        .testimonial-title {
          color: $module-testimonials-item-title-color;
        }

        .testimonial-location {
          color: $module-testimonials-item-location-color;
        }
      }

      .testimonial-content {
        .testimonial-contact-name {
          font-family: $module-testimonials-item-contact-name-font-family;
        }
      }
    }
  }
}
