.websiteFooter {

  background-color: $footer-bgcolor;
  color: $footer-color;

  .footerWidgets {

    // CUSTOM FOOTER ORDER
    .col.col-lg-3 {
      text-align: center;
      max-width: 100%;

      @include respond-to(xl-lg) {
        max-width: 25%;
      }
    }

    #menu-3 {
      display: flex;
      flex-direction: column;
      text-align: center;

      @media (min-width: 1200px) {
        flex-direction: row;
      }

      .widgetTitle {
        text-align: center;
      }

      ul {
        align-items: center;
      }

      .col.inactive.level-1.type-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        ul {
          align-items: center;
        }

      }
    }

    div.col.footerMenu:last-of-type {
      max-width: 100%;
      justify-content: center;
      padding: 0;

      @include respond-to('sm') {
        max-width: 25%;
        margin: 0 auto;
      }

      .openingsTijden {
        text-align: center;
        max-width: 100%;

        @include respond-to('sm') {
          max-width: fit-content;
        }

        .flex_tijden {
          display: flex;
          width: 220px;
          justify-content: space-between;
        }
      }
    }

    .widgetTitle {
      color: $footer-title-color;
    }

    .widget.company {
      .d-flex {
        justify-content: center;
      }

      i {
        color: $footer-icon-color;

      }

      .company.name {
        color: $footer-color;
      }

      a {
        color: $footer-menu-color;

        &:hover {
          color: $footer-menu-color-hover;
        }
      }
    }

    .widget.offices,
    .widget.branches {
      .widgetContent {

        .office,
        .branch {

          .branchName,
          .officeName {

            .branchName,
            .officeName {
              color: $footer-color;
            }
          }

          a {
            color: $footer-menu-color;

            i {
              color: $brand-color;
            }

            &:hover {
              color: $footer-menu-color-hover;
            }
          }

          .officeFax {
            i {
              color: $brand-color;
            }
          }
        }
      }

      &.minimal {
        ul {
          li {
            a {
              color: $footer-menu-color;

              i {
                color: $footer-icon-color;
              }

              &:hover {
                color: $footer-menu-color-hover;
              }
            }
          }
        }
      }
    }

    .footerMenu {



      ul {

        li {
          a {
            color: $footer-menu-color;

            i {
              color: $footer-icon-color;
            }

            &:hover {
              color: $footer-menu-color-hover;
            }
          }
        }
      }
    }
  }

  .footerBottom {
    border-top: 1px solid $footer-sub-border-color;

    .copyright {
      color: $footer-sub-copyright-color;
    }

    .subfooterMenu {

      >li {
        >a {
          border-bottom: 1px solid $footer-sub-menu-color;
          color: $footer-sub-menu-color;

          &:hover {
            border-bottom: 1px solid $footer-sub-menu-color-hover;
            color: $footer-sub-menu-color-hover;
          }
        }
      }
    }
  }
}


div.footerWidgets div:nth-child(1) {
  order: 1;
}


.vbo_beeldmerk {
  display: block;
  height: auto;
  width: 5rem;
}
