/* Button variants*/
/* -------------------------*/
/* Easily pump out default styles, as well as :hover, :focus, :active,*/
/* and disabled options for all buttons*/
@mixin button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:hover,
    &:focus,
    &:active,
    &.active {
        color: $color;
        background-color: darken($background, 8%)!important;
        border-color: darken($border, 8%)!important;
    }

    .open & {
        &.dropdown-toggle {
            color: $color;
            background-color: darken($background, 8%);
            border-color: darken($border, 8%);
        }
    }

    &:active,
    &.active {
        background-image: none;
    }

    .open & {
        &.dropdown-toggle {
            background-image: none;
        }
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

/* Button sizes*/
/* -------------------------*/
@mixin button-size($padding-vertical, $padding-horizontal, $border-radius) {
    padding: $padding-vertical $padding-horizontal;
    border-radius: $border-radius;
}

.btn {
    display: inline-block;
    margin-bottom: 0;

    /* For input.btn*/
    font-weight: $btn-font-weight;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;

    /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214*/
    border: 1px solid transparent;    
    white-space: nowrap;

    @include button-size($padding-base-vertical, $padding-base-horizontal, $border-radius-base);

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        pointer-events: none;

        /* Future-proof disabling of clicks*/
   
    }
}

/* Alternate buttons*/
/* --------------------------------------------------*/

.btn-default {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

/* Success appears as green*/
.btn-success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

/* Info appears as blue-green*/
.btn-info {
    @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

/* Warning appears as orange*/
.btn-warning {
    @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

/* Danger and error appear as red*/
.btn-danger {
    @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

/* Link buttons*/
/* -------------------------*/

/* Make a button look and behave like a link*/
.btn-link {
    color: $link-color;
    font-weight: normal;
    cursor: pointer;
    border-radius: 0;

    &,
    &:active,
    &[disabled],
    fieldset[disabled] & {
        background-color: transparent;
    }

    &,
    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
    }

    &:hover,
    &:focus {
        color: $link-hover-color;
        text-decoration: underline;
        background-color: transparent;
    }

    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus {
            color: $btn-link-disabled-color;
            text-decoration: none;
        }
    }
}

/* Button Sizes*/
/* --------------------------------------------------*/

.btn-lg {
    /* line-height: ensure even-numbered height of button next to large input*/
    @include button-size($padding-large-vertical, $padding-large-horizontal, $border-radius-large);
}

.btn-sm {
    /* line-height: ensure proper height of button next to small input*/
    @include button-size($padding-small-vertical, $padding-small-horizontal, $border-radius-small);
}

.btn-xs {
    @include button-size($padding-xs-vertical, $padding-xs-horizontal, $border-radius-small);
}

/* Block button*/
/* --------------------------------------------------*/

.btn-block {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

/* Vertically space out multiple block buttons*/
.btn-block + .btn-block {
    margin-top: 5px;
}

/* Specificity overrides*/
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}

/* custom buttons */

.btn-icon {
    padding-left: 32px;
    position: relative;

    i {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 25px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px 0 0 4px;
    }

    &.btn-icon-right {
        padding-left: 12px;
        padding-right: 32px;

        i {
            right: 0;
            left: auto;
            border-radius: 0 4px 4px 0;
        }
    }

    &.btn-xs {
        padding-left: 25px;

        i {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 11px;
            border-radius: 3px 0 0 3px;
        }

        &.btn-icon-right {
            padding-left: 5px;
            padding-right: 25px;

            i {
                border-radius: 0 3px 3px 0;
            }
        }
    }

    &.btn-sm {
        padding-left: 32px;

        i {
            width: 25px;
            height: 28px;
            line-height: 28px;
            font-size: 11px;
            border-radius: 3px 0 0 3px;
        }

        &.btn-icon-right {
            padding-left: 10px;
            padding-right: 35px;

            i {
                border-radius: 0 3px 3px 0;
            }
        }
    }

    &.btn-lg {
        padding-left: 50px;

        i {
            width: 40px;
            height: 44px;
            line-height: 44px;
            font-size: 22px;
            border-radius: 6px 0 0 6px;
        }

        &.btn-icon-right {
            padding-left: 16px;
            padding-right: 55px;

            i {
                border-radius: 0 6px 6px 0;
            }
        }
    }

    &.btn-default i {
        background: darken($btn-default-bg, 11%);
    }

    &.btn-primary i {
        background: darken($btn-primary-bg, 11%);
    }

    &.btn-success i {
        background: darken($btn-success-bg, 11%);
    }

    &.btn-info i {
        background: darken($btn-info-bg, 11%);
    }

    &.btn-warning i {
        background: darken($btn-warning-bg, 11%);
    }

    &.btn-danger i {
        background: darken($btn-danger-bg, 11%);
    }
}

/* Icon container */
.icon-container {
    position: relative;
    display: inline-block;

    i {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 25px;
        height: 31px;
        line-height: 31px;
        background: darken($btn-default-bg, 12%);
        border-radius: 4px 0 0 4px;
    }

    .btn {
        padding-left: 32px;
    }

    &.icon-container-xs {
        i {
            width: 20px;
            height: 19px;
            line-height: 19px;
            font-size: 11px;
            border-radius: 3px 0 0 3px;
            top: 2px;
        }

        .btn-xs {
            padding-left: 25px;
        }
    }

    &.icon-container-sm {
        i {
            width: 25px;
            height: 28px;
            line-height: 28px;
            font-size: 13px;
            border-radius: 3px 0 0 3px;
        }

        .btn-sm {
            padding-left: 30px;
        }
    }

    &.icon-container-lg {
        i {
            width: 40px;
            height: 44px;
            line-height: 44px;
            font-size: 22px;
            border-radius: 6px 0 0 6px;
        }

        .btn-lg {
            padding-left: 50px;
        }
    }

    &.icon-container-default i {
        background: darken($btn-default-bg, 11%);
    }

    &.icon-container-primary i {
        background: darken($btn-primary-bg, 11%);
    }

    &.icon-container-success i {
        background: darken($btn-success-bg, 11%);
    }

    &.icon-container-info i {
        background: darken($btn-info-bg, 11%);
    }

    &.icon-container-warning i {
        background: darken($btn-warning-bg, 11%);
    }

    &.icon-container-danger i {
        background: darken($btn-danger-bg, 11%);
    }
}