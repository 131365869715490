.company__details .widget.social .social {
    border: 1px solid #fff;
    color: #fff;
    &:hover {
      text-decoration: none;
    }
}

.widget.social {
  .social {
    border: 1px solid $footer-color;
    color: $footer-color;
    &:hover {
      text-decoration: none;
    }

    &.social_facebook {
      &:hover {
        color: #fff;
        background-color: $facebook_color;
        border-color: $facebook_color;
      }
    }

    &.social_twitter {      
      &:hover {
        color: #fff;
        background-color: $twitter_color;
        border-color: $twitter_color;
      }
    }

    &.social_linkedin {      
      &:hover {
        color: #fff;
        background-color: $linkedin_color;
        border-color: $linkedin_color;
      }
    }

    &.social_youtube {      
   
      &:hover {
        color: #fff;
        background-color: $youtube_color;
        border-color: $youtube_color;
      }
    }

    /* Convert Google+ to Instagram */
    &.social_googleplus {
      
      i {
        &:before {
          content: "\f16d";
        }
      }
      
      &:hover {
        background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
        opacity: 0.8;
      }
    }
    

    &.social_instagram {      
      &:hover {
        color: #fff;
        background-color: $instagram_color;
      border-color: $instagram_color;
      }
    }

    &.social_pinterest {      
      &:hover {
        color: #fff;
        background-color: $pinterest_color;
        border-color: $pinterest_color;
      }
    }
  }
}