.specials,
.recentlyviewed {
  background-color: $specials-widget-bg;

  .noslider {
    grid-template-columns: 1fr;

    @include respond-to("sm") {
      grid-template-columns: 1fr 1fr;
    }

    @include respond-to("lg") {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .owl-nav {
    [class*="owl-"] {
      color: $slider-nav-color;
      background: $slider-nav-bgcolor;

      &:hover {
        background: darken($slider-nav-bgcolor, 5%);
        color: $slider-nav-color;
      }
    }
  }

  .object {
    border-radius: $border-radius-default;
    background-color: $specials-widget-object-bg;
    color: $specials-widget-object-color;

    &:before {
      background-color: $specials-widget-object-hover-border;
    }

    .object_status {
      border-radius: 0 0 $border-radius-default 0;
      background-color: $specials-widget-status-normal-bg;
      color: $specials-widget-status-normal-color;

      &.rented,
      &.sold {
        background-color: $specials-widget-status-sold-rented-bg;
        color: $specials-widget-status-sold-rented-color;
      }

      &.rented_under_conditions,
      &.sold_under_conditions {
        background-color: $specials-widget-status-under-conditions-bg;
        color: $specials-widget-status-under-conditions-color;
      }

      &.new_forsale,
      &.new_forrent {
        background-color: $specials-widget-status-new-bg;
        color: $specials-widget-status-new-color;
      }

      &.price_changed {
        background-color: $specials-widget-status-pricechanged-bg;
        color: $specials-widget-status-pricechanged-color;
      }

      &.open_house {
        background-color: $specials-widget-status-openhouse-bg;
        color: $specials-widget-status-openhouse-color;
      }
    }

    .img-container {
      border-radius: $border-radius-default $border-radius-default 0 0;
    }

    .data {
      box-shadow: 0 2px 0 0 darken($specials-widget-object-bg, 8%);

      .obj_header {
        .obj_price {
          color: $brand-color;
        }
      }

      .obj_features {
        border-top: 1px solid $specials-widget-feature-border-color;

        > span {
          border-right: 1px solid $specials-widget-feature-border-color;

          i {
            color: $specials-widget-feature-icon-color;
          }
        }
      }
    }
  }
}

.searchdetail {
  .object_detail_contact img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top;
  }
}

.searchdetail.berlin {
  .wrapper {
    .objectheader {
      .mainphoto {
        @include respond-to(md-max) {
          margin-bottom: 20px;
          height: 400px !important;
        }

        @include respond-to(sm-max) {
          height: 200px !important;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          content: "";
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
          );
        }
      }
    }

    .realtorinfo-large {
      display: block;
    }

    .realtorinfo-small {
      background-position: top;
    }
  }
}

#onlinebiedingpopup {
  iframe {
    width: 100%;
    max-height: 100vh;
    min-height: 800px;
    border: none;
    margin: 0;

    .thankyou {
      padding: 15px;
    }
  }
}
.berlin {
  .openhouse {
    pre {
      white-space: normal;
      line-height: 1.6;
    }
  }
}
